import React, { useEffect, useState } from "react";
import styles from "./Insights.module.scss";
import InsightsItem from "./InsightsItem";
import Button from "app/components/button/button";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useHook } from "app/hooks/common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DirectusImage } from "app/core/common";
import Loader from "app/components/Loader";

export default function Insights() {
  const { cookie } = useHook();
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [transactionsList, setTransactionsList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payoutRequested, setPayoutRequested] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const id = location.pathname.split("/")[2];

  /* ====================== */
  /* Get Launchpad information */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/insights/${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });

        setCollectionInfo(result);
        setLoading(false);
      } catch (error) {
        history.push(`/`);
        console.error(error);
      }
    };
    fetchData();
  }, [cookie, history, id]);
  /* ====================== */
  /* Get Launchpad information */
  /* ======== End ======= */

  /* ====================== */
  /* Get Collection buyers information */
  /* ======== Start ======= */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/insights/transactions/${id}/1`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });
        setTransactionsList(list);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [collectionInfo, cookie, id]);

  /* ====================== */
  /* Get Collection buyers information */
  /* ======== Start ======= */

  /* ====================== */
  /* Handle Payout Request */
  /* ======== Start ======= */
  const handlePayoutRequest = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/request_payout/${id}`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          user_cookie: cookie,
        },
      });

      if (response.status === 200) {
        setPayoutRequested(true);
      }
    } catch (error) {
      console.error("Error requesting payout:", error);
    } finally {
      setLoading(false);
    }
  };
  /* ======== End ======= */

  // send the user to in live view
  const handleViewLive = () => {
    window.open(`https://app.loop.fans/collection/${id}`, "_blank");
  };

  // send the user to edit nft view
  const handleEdit = (collectionInfo) => {
    if (
      collectionInfo?.data[0]?.collection_type === "collectible" ||
      "support"
    ) {
      history.push(`/editCollection/${id}`);
    } else if (collectionInfo?.data[0]?.collection_type === "vote") {
      history.push(`/editNftCollection/${id}`);
    } else {
      return;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "0 auto",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.insights_container}>
      <div className={styles.insights_title}>
        <h1>Insights</h1>
      </div>
      <div className={styles.insights_infos}>
        <div className={styles.insight_image}>
          {collectionInfo?.data[0]?.artwork?.id ? (
            <LazyLoadImage
              src={DirectusImage(collectionInfo?.data[0]?.artwork?.id)}
              effect="blur"
              wrapperClassName={styles.image}
            />
          ) : null}
        </div>

        <div className={styles.insights_data}>
          <span>{collectionInfo?.data[0]?.name}</span>
          <p className={styles.description}>
            Gain valuable insights into your music and NFT sales. Track your
            performance, understand your audience, and optimize your strategy to
            maximize your success.
          </p>
          <div className={styles.button_container}>
            <Button size="sm" onClick={() => handleViewLive()}>
              View Live
            </Button>
            <Button size="sm" onClick={() => handleEdit(collectionInfo)}>
              <div style={{ width: "70px" }}>Edit</div>
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.insights_table}>
        <div className={styles.insights_header}>
          {/* <div className={styles.insights_data}>
            <span>NFTs sold:</span>
            {collectionInfo?.data[0]?.sold}
          </div> */}
          <div className={styles.insights_data}>
            <span className={styles.section_title}>Total NFTs</span>
            {collectionInfo?.data?.length >= 3 ? (
              <div className={styles.nft_list}>
                {collectionInfo?.data?.map((nft, index) => (
                  <div className={styles.nft_item} key={index}>
                    <div className={styles.nft_info}>
                      <span className={styles.nft_name}>{nft?.name}</span>
                      <span className={styles.nft_count}>{nft?.maxSupply}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.nft_total}>
                {collectionInfo?.data?.map((nft, index) => (
                  <div className={styles.nft_item} key={index}>
                    <div className={styles.nft_info}>
                      <span className={styles.nft_count}>{nft?.maxSupply}</span>
                      <span className={styles.nft_label}>Total Supply</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.insights_data}>
            <span className={styles.section_title}>Profit Overview</span>
            <div className={styles.profit_details}>
              <div className={styles.profit_stats}>
                <div className={`${styles.stat_item} ${styles.stat_item_full}`}>
                  <span className={styles.stat_label}>Total NFTs Sold</span>
                  <span className={styles.stat_value}>{collectionInfo?.data?.[0].sold}</span>
                </div>
                <div className={styles.stat_grid}>
                  <div className={styles.stat_item}>
                    <span className={styles.stat_label}>Total Sales</span>
                    <span className={styles.stat_value}>${collectionInfo?.data?.[0].total}</span>
                  </div>
                  <div className={styles.stat_item}>
                    <span className={styles.stat_label}>Platform Fee</span>
                    <span className={styles.stat_value}>-12%</span>
                  </div>
                </div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.profit_section}>
                <p className={styles.total_profit}>
                  ${collectionInfo
                    ? collectionInfo?.data
                        .reduce(
                          (totalProfit, nft) =>
                            totalProfit + parseFloat(nft?.profit),
                          0
                        )
                        .toFixed(2)
                    : "0.00"}
                </p>
                <button 
                  className={styles.payout_button}
                  onClick={handlePayoutRequest}
                  disabled={loading || payoutRequested || !collectionInfo?.data?.[0]?.profit || parseFloat(collectionInfo?.data?.[0]?.profit) <= 0}
                >
                  {loading ? (
                    <span className={styles.loading}>Processing...</span>
                  ) : payoutRequested ? (
                    <span className={styles.requested}>Payout Requested</span>
                  ) : (
                    <>
                      <span>Request Payout</span>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4V20M12 20L18 14M12 20L6 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.history_section}>
        <h2 className={styles.section_title}>Transaction History</h2>
        <div className={styles.history_table}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Collection</th>
                <th>Buyer</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList?.data?.map((user, index) => (
                <InsightsItem key={index} user={user} />
              ))}
              {(!transactionsList?.data || transactionsList?.data.length === 0) && (
                <tr className={styles.empty_state}>
                  <td colSpan={5}>
                    <div className={styles.empty_content}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span>No transactions yet</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
