import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
//import logo from "../assets/logo.svg";
import logo from "../assets/logo.png";
import caret from "../assets/caret.svg";
import Button from "app/components/button/button";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { DefaultAvatar } from "app/core/common";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { DirectusImage } from "../app/core/common";
import { truncate } from "app/helpers/helper";
import { useLogin } from "app/context/loginProvider";
import EmailIcon from "@mui/icons-material/Email";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MenuIcon from "@mui/icons-material/Menu";

type HeaderProps = {
  noheader: boolean;
  onToggleSidebar?: () => void;
  isSidebarOpen?: boolean;
};

export default function Header({
  noheader,
  onToggleSidebar,
  isSidebarOpen,
}: HeaderProps) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { showSignup, toggleLoginModal, toggleSignupModal } = useLogin();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const origin = window.location.origin;
  const pathname = window.location.pathname;
  const inbox = {
    name: "Inbox",
    to: `/inbox/?tab=inbox`,
    icon: origin + "/assets/icons/inbox",
    auth: true,
    type: "page",
    tab: ["inbox"],
  };

  {
    /* add more actions here... */
  }
  const actions = [
    {
      name: <Trans>profile</Trans>,
      to: `https://loop.fans/user/${userInfo?.username}/`,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <div className="container">
          <div className={styles.items}>
            <div className={styles.left}>
              <button className={styles.menuButton} onClick={onToggleSidebar}>
                <MenuIcon />
              </button>
              <Link to="/">
                <img src={logo} alt="Logo" height={40} />
              </Link>
            </div>

            <div className={styles.right}>
              {/* {noheader ? null : (
                <div className={styles.actions}>
                  <LanguageSelector />
                </div>
              )} */}
              {noheader ? null : (
                <>
                  {userInfo ? (
                    <div className={styles.profile}>
                      {/* <Button className={styles.backButton} onClick={() => window.open("https://www.loop.fans", "_blank")}>
                          Back to loop.fans
                        </Button> */}

                      <img
                        src={
                          userInfo?.avatar
                            ? DirectusImage(userInfo?.avatar)
                            : DirectusImage(DefaultAvatar)
                        }
                        alt="loop-user-picture"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      />

                      {dropdownOpen && (
                        <div className={styles.dropdown_content}>
                          <a
                            href="https://www.loop.fans"
                            target="_blank"
                            onClick={() => {
                              logout();
                              //window.Bugpilot.logout();
                              window.location.reload();
                            }}
                          >
                            Back to loop.fans
                          </a>
                          <Link
                            to="#"
                            onClick={() => {
                              logout();
                              //window.Bugpilot.logout();
                              window.location.reload();
                            }}
                          >
                            Logout
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {noheader ? null : (
                        <>
                          <Button
                            className={styles.signupButton}
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(false);
                            }}
                          >
                            Login
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
