import { useState, useCallback, useEffect } from "react";
import {
  FiUpload,
  FiX,
  FiPlus,
  FiImage,
  FiVideo,
  FiFileText,
  FiArrowLeft,
} from "react-icons/fi";
import styles from "./ContentBlocksForm.module.scss";
import { CircularProgress } from "@mui/material";
import { GalleryImages } from "./GalleryImages";
import { useHook } from "app/hooks/common";

interface DirectusFile {
  id: string;
}

interface GalleryImage {
  featured: boolean;
  directus_files_id: {
    id: string;
  };
  localFile?: File;
  localPreview?: string;
  isUploading?: boolean;
  id?: string;
}

interface Gallery {
  id: string;
  images: GalleryImage[];
}

export interface ContentBlockFormData {
  id?: string;
  name: string;
  description: string;
  access_type: "public" | "private";
  content_type: "gallery" | "video" | "article";
  gallery?: Gallery;
  files?: File[];
  videoIds?: string[];
  data?: any;
}

interface GalleryFormProps {
  contentBlockId?: any;
  initialData?: ContentBlockFormData;
  onSubmit: (data: ContentBlockFormData) => void;
  onCancel: () => void;
  isLoading?: boolean;
  contentType?: "gallery" | "video" | "article" | null;
  isEditing?: boolean;
}

export function ContentBlockForm({
  contentBlockId,
  initialData,
  onSubmit,
  onCancel,
  isLoading,
  contentType,
  isEditing
}: GalleryFormProps) {
  console.log({ initialData });
  const { cookie } = useHook();
  const [step, setStep] = useState<"type" | "content" | "gallery">(
    isEditing ? "content" : "type"
  );
  const [formData, setFormData] = useState<ContentBlockFormData>({
    name: initialData?.name || "",
    description: initialData?.description || "",
    access_type: initialData?.access_type || "public",
    content_type: contentType || initialData?.content_type || "gallery",
    gallery: initialData?.gallery || { id: "", images: [] },
    files: [],
    videoIds: [],
  });
  const [images, setImages] = useState<GalleryImage[]>(
    initialData?.gallery?.images || []
  );
  const [videoIds, setVideoIds] = useState<string[]>(
    initialData?.videoIds || [""]
  );
  const [videoErrors, setVideoErrors] = useState<{[key: string]: boolean}>({});
  const [videoLoading, setVideoLoading] = useState<{[key: string]: boolean}>({});
  const [isUploading, setIsUploading] = useState(false);

  const validateYoutubeId = (id: string): boolean => {
    // Basic validation for YouTube video ID format
    return /^[a-zA-Z0-9_-]{11}$/.test(id.trim());
  };

  useEffect(() => {
    if (isEditing && step === "type") {
      setStep("content");
    }
  }, [isEditing, step]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE}file/upload`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
          headers: {
            user_cookie: cookie,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to upload file: ${response.statusText}`);
      }

      const fileId = await response.text();
      console.log("Uploaded file ID:", fileId);

      if (!fileId || typeof fileId !== "string") {
        throw new Error("Invalid response from server");
      }

      return fileId.trim();
    } catch (error) {
      console.error("Upload error details:", error);
      throw error;
    }
  };

  const handleTypeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      content_type: formData.content_type,
    }));
    setStep("content");
  };

  const handleContentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      name: formData.name,
      description: formData.description,
      access_type: formData.access_type,
    }));
    setStep("gallery");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsUploading(true);

      if (formData.content_type === "video") {
        try {
          // Filter out empty video IDs and create embed objects
          const filteredVideoIds = videoIds.filter((id) => id.trim() !== "");
          const embedObjects = filteredVideoIds.map((id) => ({
            embed_id: id.trim(),
          }));

          const response = await fetch(
            `${process.env.REACT_APP_MIDDLEWARE}blocks/youtube_block`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                user_cookie: cookie,
              },
              body: JSON.stringify({
                title: formData.name,
                embeds: embedObjects,
                description: formData.description,
                domain: window.location.hostname,
                blockId: contentBlockId,
              }),
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error(
              `Failed to create YouTube block: ${response.statusText}`
            );
          }

          const data = await response.json();
          console.log("YouTube block created:", data);
          onSubmit(data);
          return;
        } catch (error) {
          console.error("Error creating YouTube block:", error);
          throw error;
        }
      }

      if (formData.content_type === "gallery") {
        // Get all images that need to be uploaded
        const imagesToUpload = images.filter((img) => img.localFile);
        console.log("Images to upload:", imagesToUpload);

        // Keep track of all updated images
        let updatedImages = [...images];

        // Upload files sequentially
        for (const image of imagesToUpload) {
          try {
            // Mark current image as uploading
            updatedImages = updatedImages.map((img) =>
              img.directus_files_id.id === image.directus_files_id.id
                ? { ...img, isUploading: true }
                : img
            );
            setImages(updatedImages);

            // Upload the file
            const fileId = await uploadFile(image.localFile!);
            console.log("Uploaded file ID:", fileId);

            // Update the image with the new file ID
            updatedImages = updatedImages.map((img) =>
              img.directus_files_id.id === image.directus_files_id.id
                ? {
                    ...img,
                    directus_files_id: { id: fileId },
                    isUploading: false,
                    localFile: undefined,
                  }
                : img
            );
            setImages(updatedImages);
          } catch (error) {
            console.error("Failed to upload file:", error);
            // Show a more detailed error message to the user
            alert(
              `Failed to upload image: ${
                error instanceof Error ? error.message : "Unknown error"
              }`
            );
            // Remove the failed image
            updatedImages = updatedImages.filter(
              (img) => img.directus_files_id.id !== image.directus_files_id.id
            );
            setImages(updatedImages);
          }
        }

        console.log("Final images:", updatedImages);

        const galleryData: ContentBlockFormData = {
          ...formData,
          name: formData.name,
          description: formData.description,
          access_type: formData.access_type,
          content_type: formData.content_type,
          gallery: {
            id: formData.gallery?.id || "",
            images: updatedImages.map((img) => ({
              featured: img.featured,
              directus_files_id: {
                id: img.directus_files_id.id,
              },
            })),
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE}blocks/album_block`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              user_cookie: cookie,
            },
            body: JSON.stringify({
              contentBlockId,
              ...galleryData,
            })
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to create gallery block: ${response.statusText}`
          );
        }

        const data = await response.json();
        console.log("Album block created:", data);
        onSubmit(data);
        return;
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("Failed to create content. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    // setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    // setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    // setIsDragging(false);
    // const file = e.dataTransfer.files[0];
    // if (file && file.type.startsWith('image/')) {
    //   setCoverImage(file);
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setCoverImagePreview(reader.result as string);
    //   };
    //   reader.readAsDataURL(file);
    // }
  }, []);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // const file = e.target.files?.[0];
      // if (file) {
      //   setCoverImage(file);
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     setCoverImagePreview(reader.result as string);
      //   };
      //   reader.readAsDataURL(file);
      // }
    },
    []
  );

  const handleAddVideoId = () => {
    setVideoIds([...videoIds, ""]);
  };

  const handleRemoveVideoId = (index: number) => {
    const newVideoIds = videoIds.filter((_, i) => i !== index);
    if (newVideoIds.length === 0) {
      setVideoIds([""]); // Always keep at least one input field
    } else {
      setVideoIds(newVideoIds);
    }
  };

  const handleVideoIdChange = (index: number, value: string) => {
    const newVideoIds = [...videoIds];
    newVideoIds[index] = value;
    setVideoIds(newVideoIds);

    // Set loading state while validating
    if (value.trim() !== "") {
      setVideoLoading(prev => ({ ...prev, [index]: true }));
      
      // Simulate a small delay to show loading state
      setTimeout(() => {
        // Validate the video ID
        const isValid = validateYoutubeId(value);
        setVideoErrors(prev => ({
          ...prev,
          [index]: !isValid && value.trim() !== ""
        }));
        setVideoLoading(prev => ({ ...prev, [index]: false }));
      }, 500);
    } else {
      setVideoErrors(prev => ({ ...prev, [index]: false }));
      setVideoLoading(prev => ({ ...prev, [index]: false }));
    }
  };

  return (
    <div className={styles.container}>
      <form
        onSubmit={
          step === "type"
            ? handleTypeSubmit
            : step === "content"
            ? handleContentSubmit
            : handleSubmit
        }
        className={styles.form}
      >
        <div className={styles.header}>
          <div>
            <h1>{initialData ? "Edit Content" : "Create Content"}</h1>
            <p>Choose your content type and customize information</p>
            <small>
              Your content will be displayed on your discover page
            </small>
          </div>
          <div className={styles.stepIndicator}>
            <div
              className={`${styles.step} ${
                step === "type" ? styles.active : styles.completed
              }`}
            >
              1. Type
            </div>
            <div
              className={`${styles.step} ${
                step === "content"
                  ? styles.active
                  : step === "gallery"
                  ? styles.completed
                  : ""
              } ${formData.content_type ? "" : styles.disabled}`}
            >
              2. Content
            </div>
            <div
              className={`${styles.step} ${
                step === "gallery" ? styles.active : ""
              } ${formData.name ? "" : styles.disabled}`}
            >
              3. Data
            </div>
          </div>
        </div>

        {step === "type" ? (
          <div className={styles.formContent}>
            <div className={styles.formGroup}>
              <label>Content Type</label>
              <div className={styles.analytics_grid}>
                <button
                  className={`${styles.analytics_card} ${
                    formData.content_type === "gallery" ? styles.selected : ""
                  }`}
                  onClick={() => setFormData((prev) => ({ ...prev, content_type: "gallery" }))}
                >
                  <FiImage size={24} />
                  <h3>Photo Album</h3>
                  <p>Create a collection of photos</p>
                </button>
                <button
                  className={`${styles.analytics_card} ${
                    formData.content_type === "video" ? styles.selected : ""
                  }`}
                  onClick={() => setFormData((prev) => ({ ...prev, content_type: "video" }))}
                >
                  <FiVideo size={24} />
                  <h3>Youtube</h3>
                  <p>Showcase/Promote your videos</p>
                </button>
                <button
                  className={`${styles.analytics_card} ${
                    formData.content_type === "article" ? styles.selected : ""
                  }`}
                  onClick={() => setFormData((prev) => ({ ...prev, content_type: "article" }))}
                  disabled
                >
                  <FiFileText size={24} />
                  <h3>Share Links</h3>
                  <p>Share links to your content with images and text</p>
                </button>
              </div>
            </div>
          </div>
        ) : step === "content" ? (
          <div className={styles.formContent}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Content Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                placeholder="Enter content name"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
                placeholder="Describe your content"
                rows={4}
                required
              />
              <small>
                This description will help fans discover your content
              </small>
            </div>
          </div>
        ) : (
          <div className={styles.formContent}>
            {formData.content_type === "video" ? (
              <div className={styles.formGroup}>
                <label>YouTube Video IDs</label>
                <div className={styles.videoGrid}>
                  {videoIds.map((videoId, index) => (
                    <div key={index} className={styles.videoIdInput}>
                      <div className={styles.inputRow}>
                        <input
                          type="text"
                          value={videoId}
                          onChange={(e) => handleVideoIdChange(index, e.target.value)}
                          placeholder="Enter YouTube video ID (e.g., dQw4w9WgXcQ)"
                          required={index === 0}
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveVideoId(index)}
                          className={styles.removeButton}
                          disabled={videoIds.length === 1 && index === 0}
                          title={videoIds.length === 1 && index === 0 ? "Cannot remove the last video" : "Remove video"}
                        >
                          <FiX size={20} />
                        </button>
                      </div>
                      {videoId.trim() !== "" && (
                        <div 
                          className={`${styles.videoPreview} ${
                            videoErrors[index] ? styles.error : ""
                          } ${videoLoading[index] ? styles.loading : ""}`}
                        >
                          {videoLoading[index] ? (
                            <div className={styles.spinner} />
                          ) : videoErrors[index] ? (
                            <span>Invalid YouTube video ID. Please check the format.</span>
                          ) : (
                            <iframe
                              src={`https://www.youtube.com/embed/${videoId.trim()}`}
                              title={`YouTube video ${index + 1}`}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddVideoId}
                    className={styles.addVideoButton}
                  >
                    <div className={styles.icon}>
                      <FiPlus size={24} />
                    </div>
                    Add Another Video
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.formGroup}>
                <label>Content Images</label>
                <GalleryImages
                  images={images}
                  onImagesChange={setImages}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
        )}

        <div className={styles.actions}>
          {step === "type" ? (
            <>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={!formData.content_type}
              >
                Next: Add Details
              </button>
            </>
          ) : step === "content" ? (
            <>
              <button
                type="button"
                className={styles.backButton}
                onClick={() => setStep("type")}
              >
                Back to Type
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={!formData.name}
              >
                Next: Add Content
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className={styles.backButton}
                onClick={() => setStep("content")}
              >
                Back to Details
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={
                  isLoading ||
                  isUploading ||
                  (formData.content_type === "video"
                    ? videoIds.length === 0
                    : images.length === 0)
                }
              >
                {isLoading || isUploading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <>{initialData ? "Save Changes" : "Create Content"}</>
                )}
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
