import { useState, useCallback } from 'react';
import styles from './GalleryImages.module.scss';
import { FiX, FiImage, FiUpload } from 'react-icons/fi';
import { DirectusImage } from 'app/core/common';
import { useHook } from 'app/hooks/common';

interface GalleryImage {
  featured: boolean;
  directus_files_id: {
    id: string;
  };
  localFile?: File;
  localPreview?: string;
  isUploading?: boolean;
}

interface GalleryImagesProps {
  images: GalleryImage[];
  onImagesChange: (images: GalleryImage[]) => void;
  isLoading?: boolean;
}

export function GalleryImages({ images, onImagesChange, isLoading }: GalleryImagesProps) {
  const { cookie } = useHook();
  const [isDragging, setIsDragging] = useState(false);

  const createLocalPreview = async (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);

      const files = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('image/'));
      if (files.length === 0) return;

      const newImages: GalleryImage[] = await Promise.all(
        files.map(async (file) => ({
          featured: false,
          directus_files_id: {
            id: crypto.randomUUID() // Temporary ID
          },
          localFile: file,
          localPreview: await createLocalPreview(file)
        }))
      );

      onImagesChange([...images, ...newImages]);
    },
    [images, onImagesChange]
  );

  const handleFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files || []).filter(file => file.type.startsWith('image/'));
      if (files.length === 0) return;

      const newImages: GalleryImage[] = await Promise.all(
        files.map(async (file) => ({
          featured: false,
          directus_files_id: {
            id: crypto.randomUUID() // Temporary ID
          },
          localFile: file,
          localPreview: await createLocalPreview(file)
        }))
      );

      onImagesChange([...images, ...newImages]);
      
      // Reset the input
      e.target.value = '';
    },
    [images, onImagesChange]
  );

  const removeImage = useCallback(
    (index: number) => {
      const newImages = [...images];
      newImages.splice(index, 1);
      onImagesChange(newImages);
    },
    [images, onImagesChange]
  );

  return (
    <div className={styles.container}>
      <div
        className={`${styles.dropZone} ${isDragging ? styles.dragging : ''}`}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className={styles.uploadArea}>
          <FiImage size={48} />
          <p>Drag and drop your images here or</p>
          <label className={styles.uploadButton}>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileSelect}
              multiple
              hidden
              disabled={isLoading}
            />
            <FiUpload size={16} />
            Choose Files
          </label>
          <p className={styles.hint}>You can upload multiple images at once</p>
        </div>
      </div>

      {images.length > 0 && (
        <div className={styles.imageGrid}>
          {images.map((image, index) => (
            <div key={image?.directus_files_id?.id} className={styles.imagePreview}>
              <img 
                src={image.localPreview || DirectusImage(image?.directus_files_id?.id)} 
                alt={`Album image ${index + 1}`} 
              />
              {image.isUploading && (
                <div className={styles.uploadingOverlay}>
                  <div className={styles.spinner} />
                </div>
              )}
              <button
                type="button"
                className={styles.removeImage}
                onClick={() => removeImage(index)}
                disabled={isLoading || image.isUploading}
              >
                <FiX size={20} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
