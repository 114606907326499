import { 
  MusicNote as MusicNoteIcon,
  OndemandVideo as OndemandVideoIcon,
  WorkspacePremium as WorkspacePremiumIcon,
  CardMembership as CardMembershipIcon,
  Sell as SellIcon,
  HowToVote as HowToVoteIcon,
  Storefront as StorefrontIcon,
  Campaign as CampaignIcon,
  Diamond as DiamondIcon
} from "@mui/icons-material";
import { PiVinylRecord } from "react-icons/pi";

// Define categories for better organization
const CATEGORIES = {
  AVAILABLE: 'available',
  COMING_SOON: 'coming_soon'
} as const;

interface StudioOption {
  title: string;
  message: string;
  category: typeof CATEGORIES[keyof typeof CATEGORIES];
  icon: JSX.Element;
  to?: string;
  link?: string;
  available: boolean;
}

export const options: StudioOption[] = [
  {
    title: "Drops",
    message: "Create/Sell exclusive drops for your community",
    category: CATEGORIES.AVAILABLE,
    to: "/createCollection",
    available: true,
    icon: <MusicNoteIcon fontSize="large" />,
  },
  {
    title: "Smart Vinyl",
    message: "Connect digital experiences to physical vinyl records",
    category: CATEGORIES.AVAILABLE,
    available: true,
    icon: <PiVinylRecord fontSize={40} />,
    link: "https://smartvinyl.io/",
  },
  /* {
    title: "Premium Content",
    message: "Share exclusive content with your dedicated fans",
    category: CATEGORIES.COMING_SOON,
    available: false,
    icon: <WorkspacePremiumIcon fontSize="large" />,
  }, */
  {
    title: "Fan Membership",
    message: "Create exclusive membership tiers for your community",
    category: CATEGORIES.COMING_SOON,
    available: false,
    icon: <CardMembershipIcon fontSize="large" />,
  },
  {
    title: "Merch",
    message: "Sell digital collectibles redeemable for physical merch",
    category: CATEGORIES.COMING_SOON,
    available: false,
    icon: <SellIcon fontSize="large" />,
  },
  {
    title: "Tickets",
    message: "Create exclusive tickets for your events",
    category: CATEGORIES.COMING_SOON,
    available: false,
    icon: <OndemandVideoIcon fontSize="large" />,
  },
  {
    title: "Fan Engagement",
    message: "Reward your fans for participating in social interactions",
    category: CATEGORIES.COMING_SOON,
    available: false,
    icon: <HowToVoteIcon fontSize="large" />,
  }
];
