import { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../button/button";
import styles from "./login.module.scss";
import LoginPage from "app/pages/Login/Login";
import { useHook } from "app/hooks/common";

import logo from "../../../assets/logo.png";
import checkmark from "../../../assets/icons/check.svg";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useLogin } from "app/context/loginProvider";

interface LoginProps {
  showLoginModal: boolean;
  showSignup: boolean;
  artistSignup?: boolean;
}

export default function LoginModal(props: LoginProps) {
  const { showLoginModal, showSignup, artistSignup } = props;
  const { address } = useHook();
  const { toggleLoginModal, toggleSignupModal } = useLogin();
  const checkList = [
    "Vote for Artists in the Web3 Music Awards",
    "Access exclusive content and Media",
  ];

  useEffect(() => {
    if (address && showLoginModal) {
      toggleLoginModal();
    }
  }, [address]);

  return (
    <div className={styles.login_modal}>
      <Modal
        isOpen={showLoginModal}
        title=""
        onClose={() => {
          toggleLoginModal();
        }}
        customClass="pauseModal"
        login={true}
      >
        <div className={styles.modalContent}>
          <div className={styles.header}>
            <img className={styles.logo} src={logo} alt="logo" />
            {!artistSignup && (
              <h3>
                {showSignup ? (
                  <Trans>signupTitle</Trans>
                ) : (
                  <Trans>loginTitle</Trans>
                )}
              </h3>
            )}
            {showSignup && (
              <p className={styles.subtitle}>
                Get free access to explore VIP Clubs and special benefits
              </p>
            )}
          </div>

          {!artistSignup && showSignup && (
            <div className={styles.list}>
              {checkList?.map((item, index) => (
                <p key={index}>
                  <img src={checkmark} alt="check" /> {item}
                </p>
              ))}
            </div>
          )}

          <div className={styles.content}>
            <LoginPage
              artistSignup={artistSignup}
              showSignup={showSignup}
              setSignupform={toggleSignupModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
