import React, { useState, useMemo } from 'react';
import styles from './Fans.module.scss';
import { FiMail, FiUser, FiArrowDown } from 'react-icons/fi';

interface Fan {
  id: string;
  name: string;
  email: string;
  joinDate: string;
  totalSpent: number;
  lastPurchase: string;
  status: string;
  engagement: {
    likes: number;
    comments: number;
    shares: number;
    views: number;
  };
}

interface Order {
  id: string;
  fanId: string;
  fanName: string;
  date: string;
  amount: number;
  items: string[];
  status: 'completed' | 'pending' | 'refunded';
}

type SortField = 'name' | 'joinDate' | 'engagement' | 'totalSpent' | 'freeClaims';
type SortDirection = 'asc' | 'desc';

// Helper function to calculate engagement score
const calculateEngagementScore = (engagement: Fan['engagement']) => {
  const weights = {
    likes: 1,
    comments: 2,
    shares: 3,
    views: 0.1
  };
  
  const score = (
    engagement.likes * weights.likes +
    engagement.comments * weights.comments +
    engagement.shares * weights.shares +
    engagement.views * weights.views
  );

  // Convert to percentage with max score of 100
  return Math.min(Math.round(score / 10), 100);
};

const Fans: React.FC = () => {
  // Sample data - replace with real data from API
  const [fans] = useState<Fan[]>([
    {
      id: '1',
      name: 'John Doe',
      email: 'john@example.com',
      joinDate: '2024-01-01',
      totalSpent: 249.99,
      lastPurchase: '2024-01-10',
      status: 'active',
      engagement: {
        likes: 15,
        comments: 8,
        shares: 3,
        views: 120
      }
    },
    {
      id: '2',
      name: 'Jane Smith',
      email: 'jane@example.com',
      joinDate: '2023-01-05',
      totalSpent: 149.99,
      lastPurchase: '2024-01-12',
      status: 'active',
      engagement: {
        likes: 25,
        comments: 12,
        shares: 5,
        views: 200
      }
    },
    {
      id: '3',
      name: 'Harry Smith',
      email: 'jane@example.com',
      joinDate: '2024-12-05',
      totalSpent: 149.99,
      lastPurchase: '2024-01-12',
      status: 'active',
      engagement: {
        likes: 8,
        comments: 3,
        shares: 1,
        views: 80
      }
    }
  ]);

  const [orders] = useState<Order[]>([
    {
      id: 'ORD001',
      fanId: '1',
      fanName: 'John Doe',
      date: '2024-01-10',
      amount: 99.99,
      items: ['Digital Collection #1', 'Premium Access'],
      status: 'completed'
    },
    {
      id: 'ORD002',
      fanId: '2',
      fanName: 'Jane Smith',
      date: '2024-01-12',
      amount: 149.99,
      items: ['Digital Collection #2', 'Exclusive Content'],
      status: 'completed'
    }
  ]);

  const [activeTab, setActiveTab] = useState<'fans' | 'orders'>('fans');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('engagement');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const sortedFans = useMemo(() => {
    return [...fans].sort((a, b) => {
      let compareA: string | number;
      let compareB: string | number;

      switch (sortField) {
        case 'name':
          compareA = a.name.toLowerCase();
          compareB = b.name.toLowerCase();
          break;
        case 'joinDate':
          compareA = new Date(a.joinDate).getTime();
          compareB = new Date(b.joinDate).getTime();
          break;
        case 'engagement':
          compareA = calculateEngagementScore(a.engagement);
          compareB = calculateEngagementScore(b.engagement);
          break;
        case 'totalSpent':
          compareA = a.totalSpent;
          compareB = b.totalSpent;
          break;
        case 'freeClaims':
          compareA = 0; // Replace with actual free claims when available
          compareB = 0;
          break;
        default:
          return 0;
      }

      if (compareA < compareB) return sortDirection === 'asc' ? -1 : 1;
      if (compareA > compareB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [fans, sortField, sortDirection]);

  const toggleSortDirection = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const getEngagementColor = (score: number) => {
    if (score >= 80) return styles.high;
    if (score >= 50) return styles.medium;
    return styles.low;
  };

  const formatTimeAgo = (date: string) => {
    const now = new Date();
    const joinDate = new Date(date);
    const diffInMilliseconds = now.getTime() - joinDate.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    } else if (diffInMonths > 0) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else if (diffInWeeks > 0) {
      return `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'} ago`;
    } else if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return 'Just now';
    }
  };

  const filteredFans = fans.filter(fan => 
    fan.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fan.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredOrders = orders.filter(order => 
    order.fanName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleExportEmails = () => {
    // Get all unique emails from fans
    const emails = fans.map(fan => fan.email).join('\n');
    
    // Create blob and download
    const blob = new Blob([emails], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `fan_emails_${new Date().toISOString().split('T')[0]}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.fansPage}>
      {/* <div className={styles.comingSoonOverlay}>
        <div className={styles.comingSoonContent}>
          <h1>Coming Soon</h1>
          <p>We're working hard to bring you an amazing fan management experience. Stay tuned!</p>
        </div>
      </div> */}

      <div className={styles.header}>
        <div className={styles.headerTop}>
          <div>
            <h1>Fans Dashboard</h1>
            <p>Manage your fans and their engagement, revenue, and more</p>
          </div>
          <button 
            className={styles.exportButton}
            onClick={() => {}}
            disabled
          >
            Export Emails
          </button>
        </div>
        {/* <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search fans or orders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        </div> */}
      </div>

      <div className={styles.statsCards}>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>👥</div>
          <div className={styles.statInfo}>
            <span className={styles.statLabel}>Total Fans</span>
            <span className={styles.statValue}>{fans.length}</span>
          </div>
        </div>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>💰</div>
          <div className={styles.statInfo}>
            <span className={styles.statLabel}>Total Revenue</span>
            <span className={styles.statValue}>
              ${orders.reduce((sum, order) => sum + order.amount, 0).toFixed(2)}
            </span>
          </div>
        </div>
        <div className={styles.statCard}>
          <div className={styles.statIcon}>📈</div>
          <div className={styles.statInfo}>
            <span className={styles.statLabel}>Avg. Engagement</span>
            <span className={styles.statValue}>
              {Math.round(fans.reduce((sum, fan) => sum + calculateEngagementScore(fan.engagement), 0) / fans.length)}%
            </span>
          </div>
        </div>
      </div>

      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'fans' ? styles.active : ''}`}
            onClick={() => setActiveTab('fans')}
          >
            Fans
          </button>
        </div>
      </div>

      {activeTab === 'fans' ? (
      <div className={styles.sortOptions}>
        <span className={styles.sortLabel}>Sort by:</span>
        <select 
          className={styles.sortSelect}
            value={sortField}
            onChange={(e) => setSortField(e.target.value as SortField)}
        >
          <option value="joinDate">Join Date</option>
          <option value="engagement">Engagement</option>
          <option value="totalSpent">Total Spent</option>
          <option value="name">Name</option>
          <option value="freeClaims">Free Claims</option>
        </select>
          <button 
            className={`${styles.sortDirection} ${sortDirection === 'asc' ? styles.ascending : ''}`}
            onClick={toggleSortDirection}
            title={`Sort ${sortDirection === 'asc' ? 'Descending' : 'Ascending'}`}
          >
            <FiArrowDown />
          </button>
      </div>
      ) : null}

      {activeTab === 'fans' ? (
      <div className={styles.fansGrid}>
          {sortedFans.map(fan => (
            <div key={fan.id} className={styles.fanCard}>
            <div className={styles.fanHeader}>
                <div className={styles.fanAvatar}>
                  {fan.name.charAt(0)}
                </div>
              <div className={styles.fanInfo}>
                  <h3>{fan.name}</h3>
                  <span>{fan.email}</span>
              </div>
                <span className={`${styles.status} ${styles[fan.status]}`}>
                  {fan.status}
                </span>
            </div>
            
            <div className={styles.fanStats}>
              <div className={styles.stat}>
                <span className={styles.label}>Followed Since</span>
                  <span className={styles.value}>{formatTimeAgo(fan.joinDate)}</span>
              </div>
              <div className={styles.stat}>
                <span className={styles.label}>Engagement</span>
                  <span className={styles.value}>{calculateEngagementScore(fan.engagement)}%</span>
              </div>
              <div className={styles.stat}>
                <span className={styles.label}>Free Claims</span>
                  <span className={styles.value}>0</span>
              </div>
              <div className={styles.stat}>
                <span className={styles.label}>Purchases</span>
                  <span className={styles.value}>0</span>
              </div>
            </div>

            <div className={styles.cardActions}>
                <button className={styles.viewButton}>View Profile</button>
                <button className={styles.messageButton}>Send Message</button>
            </div>
          </div>
        ))}
      </div>
      ) : (
        <div className={styles.ordersTable}>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Fan</th>
                <th>Date</th>
                <th>Items</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map(order => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.fanName}</td>
                  <td>{new Date(order.date).toLocaleDateString()}</td>
                  <td>
                    <div className={styles.itemsList}>
                      {order.items.join(', ')}
                    </div>
                  </td>
                  <td>${order.amount.toFixed(2)}</td>
                  <td>
                    <span className={`${styles.status} ${styles[order.status]}`}>
                      {order.status}
                    </span>
                  </td>
                  <td>
                    <button className={styles.actionButton}>View Details</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Fans;
