import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { editCollection, setGenreAndDivision } from "app/helpers/CreateNft";
import ImageIcon from "@mui/icons-material/Image";
import { blue } from "@mui/material/colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "app/components/button/button";
import { useHook } from "app/hooks/common";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useHistory, useLocation } from "react-router-dom";
import { benefits } from "./benefits";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";
import { DirectusAudio, DirectusImage } from "app/core/common";
import PreviewImage from "app/components/PreviewImage/PreviewImage";
import EditIcon from "@mui/icons-material/Edit";
import { truncate } from "app/helpers/helper";
import AddBenefitsModal from "./AddBenefitsModal";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import AlbumIcon from "@mui/icons-material/Album";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { pink } from "@mui/material/colors";
import DeleteBenefitsModal from "./DeleteBenefitsModal";
import Modal from "app/components/Modal";
import PulseLoader from "react-spinners/PulseLoader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Confetti from "react-confetti";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default function EditCollection() {
  const [recycle, setRecycle] = useState(true);
  const location = useLocation();
  const refSong = useRef(null);
  const refCover = useRef(null);
  const [songFile, setSongFile] = useState(null);
  const [genres, setGenres] = useState(null);
  const [genre, setGenre] = useState(null);
  const [albumGenre, setAlbumGenre] = useState(null);
  const [error, setError] = useState(null);
  const [collectionImage, setCollectionImage] = useState(null);
  const [collectionImagePreview, setCollectionImagePreview] = useState(null);
  const [savedImage, setSavedImage] = useState(null);
  const [price, setPrice] = useState(null);
  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [addSong, setAddSong] = useState(false);
  const [tracks, setTracks] = useState([]);
  const { userInfo, cookie, updateUserInfo } = useHook();
  const [status, setStatus] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [date, setDate] = useState(formatDate(new Date()));
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [collectionBenefits, setCollectionBenefits] = useState([]);
  const [showLaunchpadInfo, setShowLaunchpadInfo] = useState(false);
  const [albumName, setAlbumName] = useState(null);
  const [album, setAlbum] = useState(null);
  const [showAlbumContent, setShowAlbumContent] = useState(false);
  const [editAlbum, setEditAlbum] = useState(false);
  const [editVideo, setEditVideo] = useState(false);
  const id = location.pathname.split("/")[2];
  const [fansCollectionId, setFansCollectionId] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(false); // Add this line
  const [albumId, setAlbumId] = useState(null); // Add this line
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [addVideo, setAddVideo] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [videoName, setVideoName] = useState<string>("");
  const [video, setVideo] = useState<any>([]);
  const [showVideoContent, setShowVideoContent] = useState(false);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [videoCollectionId, setVideoCollectionId] = useState("");
  const [fileId, setFileId] = useState("");
  const [editFile, setEditFile] = useState(false);
  const [showFileContent, setShowFileContent] = useState(false);
  const [wentLive, setWentLive] = useState(false);
  const [support, setSupport] = useState<boolean>(false);
  const [deleteAlbum, setDeleteAlbum] = useState<boolean>(false);
  const [deleteVideo, setDeleteVideo] = useState<boolean>(false);
  const [deleteFile, setDeleteFile] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [collectionInfo, setCollectionInfo] = useState<boolean>(false);
  const [completeCollectionInfo, setCompleteCollectionInfo] =
    useState<boolean>(false);
  const [completeLaunchInfo, setCompleteLaunchInfo] = useState<boolean>(false);
  const [loadingSaveAndExit, setLoadingSaveAndExit] = useState<boolean>(false);
  const userId = userInfo?.id;
  const history = useHistory();
  const [isStatus, setIsStatus] = useState<boolean>(false);
  const [goLive, setGoLive] = useState<boolean>(false);
  const [showInfoPrice, setShowInfoPrice] = useState<boolean>(false);
  const [showInfoTime, setShowInfoTime] = useState<boolean>(false);
  const [showInfoTime2, setShowInfoTime2] = useState<boolean>(false);
  const [expandedAlbums, setExpandedAlbums] = useState({});
  const [expandedVideos, setExpandedVideos] = useState({});
  const [expandedFiles, setExpandedFiles] = useState({});
  const [editAlbumId, setEditAlbumId] = useState("");
  const [showPriceTooltip, setShowPriceTooltip] = useState(false);
  const [showQuantityTooltip, setShowQuantityTooltip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRecycle(false);
    }, 3500);
  }, []);

  useEffect(() => {
    if (collectionName && collectionDescription && collectionImage && status) {
      setCompleteCollectionInfo(true);
    } else {
      setCompleteCollectionInfo(false);
    }
    if (
      !startTime ||
      !endTime ||
      !quantity ||
      !price ||
      !startDate ||
      !endDate
    ) {
      setCompleteLaunchInfo(false);
    } else {
      setCompleteLaunchInfo(true);
    }
  }, [
    collectionName,
    collectionDescription,
    collectionImage,
    status,
    startTime,
    endTime,
    quantity,
    price,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 800) {
      document.body.classList.add("no-scroll");
      return () => {
        document.body.classList.remove("no-scroll");
      };
    }
  }, [screenWidth]);

  /* ====================== */
  /* Get Collection information to fill collection info */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/${id}?isId=true`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });

        const {
          data: { info },
        } = result;
        const {
          info: {
            launchpad_type: {
              0: { fan_collection, launchInfo },
            },
          },
        } = result.data;

        const { status, went_live, collection_type } = info;

        const {
          id: launchpad_id,
          name,
          collection_album,
          collection_video,
          collection_files,
          banner,
          description,
        } = fan_collection;
        setSupport(collection_type === "support");
        setWentLive(went_live);
        setCollectionName(name);
        setAlbum(collection_album);
        // setAlbumName(collection_album[0]?.name);
        // setAlbumId(collection_album[0]?.id);
        // setTracks(collection_album[0]?.tracks ?? []);
        // setGenre(collection_album[0]?.genre?.id);
        setFansCollectionId(launchpad_id);
        setEndDate(launchInfo?.endDate);
        setEndTime(launchInfo?.endTime);
        setStartDate(launchInfo?.startDate);
        setStartTime(launchInfo?.startTime);
        setPrice(launchInfo?.mintPrice);
        setQuantity(launchInfo?.maxSupply);
        setCollectionImagePreview(banner?.id);
        setCollectionImage(banner?.id);
        setSavedImage(banner?.id);
        setCollectionDescription(description);
        setStatus(status);
        setIsStatus(status === "draft" || status === "live");
        // setVideoName(collection_video[0]?.name);
        setVideo(collection_video);
        // setVideoCollectionId(collection_video[0]?.id);
        setFileId(collection_files[0]?.id);
        setFile(collection_files);
        // setFileName(collection_files[0]?.name);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [userId, fetchTrigger]);
  /* ====================== */
  /* Get Collection information to fill collection info */
  /* ======== End ======= */

  // fetch album info
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/${id}?isId=true`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        });

        if (
          result?.data?.info?.launchpad_type[0]?.fan_collection
            ?.collection_album
        ) {
          setAlbum(
            result?.data?.info?.launchpad_type[0]?.fan_collection
              ?.collection_album
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [fetchTrigger]);

  useEffect(() => {
    if (
      price &&
      status &&
      collectionName &&
      collectionDescription &&
      collectionImage &&
      quantity &&
      startDate &&
      endDate &&
      startTime &&
      endTime
    ) {
      setReady(true);
    }
  }, [
    price,
    status,
    collectionName,
    collectionDescription,
    collectionImage,
    quantity,
    startDate,
    endDate,
    endTime,
    startTime,
  ]);

  /* ====================== */
  /* Get genres of the NFT */
  /* ======== Start ======= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response, responseGeners] = await setGenreAndDivision();
        setGenres(responseGeners?.data?.genres);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  /* ====================== */
  /*  Get genres of the NFT */
  /* ======== End ======= */

  // store the selected value for status & genre
  const handleStatusGenre = (e) => {
    const { name, value } = e.target;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const label = selectedOption.label;
    if (name === "genre") {
      setGenre(value);
      setAlbumGenre(label);
    } else {
      if (value === "published" && !wentLive) {
        setStatusModal((prev) => !prev);
      }
      setStatus(value);
    }
  };

  // function to store collection image
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCollectionImagePreview(event.target.result);
    };
    setCollectionImage(file);
  };

  const handleVisitLivePage = () => {
    history.push("/liveCollections");
  };

  /* ====================== */
  /* edit the collection */
  /* ======== Start ======= */
  const editCollectionHandler = async () => {
    const collection = JSON.stringify({
      collection_name: collectionName,
      status: "published",
      collection_description: collectionDescription,
      collection_quantity: quantity,
      collection_price: price,
      collection_start_date: startDate,
      collection_end_date: endDate,
      collection_start_time: startTime,
      collection_end_time: endTime,
    });

    try {
      setLoading((prev) => !prev);
      if (savedImage === collectionImagePreview) {
        const edit = await editCollection(null, cookie, collection, id);
      } else {
        const edit = await editCollection(
          collectionImage,
          cookie,
          collection,
          id
        );
      }

      setFetchTrigger((prev) => !prev);
      setLoading((prev) => !prev);
      if (status === "draft") {
        setStatusModal(false);
        setGoLive(true);
      } else {
        history.push("/liveCollections");
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => !prev);
    }
  };
  /* ====================== */
  /*  edit the collection */
  /* ======== End ======= */

  /* ====================== */
  /* save as draft and continue */
  /* ======== Start ======= */
  const saveAsDraftAndConntinue = async () => {
    const collection = JSON.stringify({
      collection_name: collectionName,
      status: "draft",
      collection_description: collectionDescription,
      collection_quantity: quantity,
      collection_price: price,
      collection_start_date: startDate,
      collection_end_date: endDate,
      collection_start_time: startTime,
      collection_end_time: endTime,
    });

    try {
      setLoadingSaveAndExit((prev) => !prev);
      if (savedImage === collectionImagePreview) {
        await editCollection(null, cookie, collection, id);
      } else {
        await editCollection(collectionImage, cookie, collection, id);
      }
      //history.push("/");
      setFetchTrigger((prev) => !prev);
      setLoadingSaveAndExit((prev) => !prev);
      history.push("/drafts");
    } catch (error) {
      console.error(error);
      setLoadingSaveAndExit((prev) => !prev);
    }
  };
  /* ====================== */
  /*  save as draft and continue */
  /* ======== End ======= */

  const handleAddBenefit = (benefit) => {
    setCollectionBenefits((prevBenefits) =>
      prevBenefits?.includes(benefit)
        ? prevBenefits?.filter((b) => b !== benefit)
        : [...prevBenefits, benefit]
    );
  };

  /* ====================== */
  /* edit album of the Collection  */
  /* ======== End ======= */

  // /**********************/
  // // funtion control if its the same song play it, if not pause it
  // /**********************/
  // const handlePlayer = () => {
  //   setPlaySong(song);
  //   setCurrentSong(song);
  //   if (playSong === currentSong) {
  //     audioRef?.current?.play();
  //   } else {
  //     audioRef?.current?.pause();
  //   }
  // };
  // /**********************/
  // //  funtion control if its the same song play it, if not pause it
  // /**********************/
  // useEffect(() => {
  //   if (playSong === song) {
  //     audioRef?.current?.play();
  //   } else {
  //     audioRef?.current?.pause();
  //   }
  // }, [playSong]);

  const hendleCreateBenefit = async (benefit) => {
    if (benefit.benefit === "Music") {
      setAddSong((prev) => !prev);
    } else if (benefit.benefit === "Video") {
      setAddVideo((prev) => !prev);
    } else if (benefit.benefit === "File") {
      setAddFile((prev) => !prev);
    }
  };

  // Toggle album expand/collapse state
  const toggleAlbumContent = (index) => {
    setExpandedAlbums((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Toggle video expand/collapse state
  const toggleVideoContent = (index) => {
    setExpandedVideos((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Toggle file expand/collapse state
  const toggleFileContent = (index) => {
    setExpandedFiles((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className={styles.mastering_container}>
      <div className={styles.mastering_container_options}>
        <h1>Edit Drop</h1>
        <div className={styles.launchpad_info_title}>
          <div className={styles.launchpad_info_title_left}>
            <span>Information:</span>
            {completeCollectionInfo ? (
              <CheckCircleOutlineIcon
                className={styles.check_icon}
                color="success"
              />
            ) : (
              ""
            )}
          </div>
          <div
            className={styles.launchpad_info}
            onClick={() => setCollectionInfo((prev) => !prev)}
          >
            {collectionInfo ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </div>
        </div>

        {collectionInfo ? (
          <div className={styles.mastering_container_options_up}>
            <>
              <div className={styles.mastering_container_options_left}>
                <div className={styles.info_container}>
                  <span>Name</span>
                  <div className={styles.album_info}>
                    <input
                      type="text"
                      value={collectionName}
                      onChange={(e) => setCollectionName(e.target.value)}
                      placeholder="Enter collection name"
                      disabled={wentLive || support}
                    />
                  </div>
                </div>
                <div className={styles.info_container}>
                  <span>Description</span>
                  <div className={styles.description}>
                    <textarea
                      value={collectionDescription}
                      onChange={(e) => setCollectionDescription(e.target.value)}
                      placeholder="Enter collection description"
                      disabled={wentLive || support}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.mastering_container_options_rigth}>
                <div className={styles.info_container}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    Status
                  </span>

                  <div className={styles.album_info}>
                    <input
                      type="text"
                      value={status === "draft" ? "Draft" : "Live"}
                      disabled={wentLive || support || isStatus}
                    />
                  </div>
                </div>
                <div className={styles.info_container}>
                  <span>Artwork</span>
                  <div
                    className={styles.album_photo}
                    onClick={() => {
                      refCover.current.click();
                    }}
                  >
                    <ImageIcon sx={{ color: blue[700] }} />
                    <input
                      type="file"
                      ref={refCover}
                      hidden
                      disabled={wentLive || support}
                      onChange={(e) => handleNFtImage(e)}
                      accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
                    />
                    {collectionImage ? (
                      <span>Image selected</span>
                    ) : (
                      <span>No image selected</span>
                    )}
                    <span>(796 x 1058px)</span>
                  </div>
                </div>
              </div>
            </>
          </div>
        ) : null}

        <div className={styles.mastering_container_options_bottom}>
          <div className={styles.launchpad_info_title}>
            <div className={styles.launchpad_info_title_left}>
              <span>Launch Info</span>
              {completeLaunchInfo ? (
                <CheckCircleOutlineIcon
                  className={styles.check_icon}
                  color="success"
                />
              ) : (
                <ErrorOutlineIcon className={styles.error_icon} color="error" />
              )}
            </div>
            <div
              className={`${styles.launchpad_info} ${
                showLaunchpadInfo ? styles.active : ""
              }`}
              onClick={() => setShowLaunchpadInfo((prev) => !prev)}
            >
              {showLaunchpadInfo ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
          </div>
          <div
            className={`${styles.launchpad_content} ${
              showLaunchpadInfo ? styles.expanded : ""
            }`}
          >
            {showLaunchpadInfo ? (
              <>
                <div className={styles.mastering_container_launchinfo}>
                  <div className={styles.mastering_container_options_bottom_up}>
                    <div className={styles.info_container}>
                      <div className={styles.price_info}>
                        <span>Price</span>
                        <InfoOutlinedIcon
                          onMouseEnter={() => setShowPriceTooltip(true)}
                          onMouseLeave={() => setShowPriceTooltip(false)}
                        />
                        {showPriceTooltip && (
                          <div className={styles.price_tooltip}>
                            Set the price for your release. This is the
                            amount fans will pay to purchase your release. - <b>Note: Enter "0" to make this release free</b>
                          </div>
                        )}
                      </div>
                      <div className={styles.album_info}>
                        <div className={styles.currency_indicator}>$</div>
                        <input
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="0.00"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className={styles.info_container}>
                      <div className={styles.price_info}>
                        <span>Total Quantity</span>
                        <InfoOutlinedIcon
                          onMouseEnter={() => setShowQuantityTooltip(true)}
                          onMouseLeave={() => setShowQuantityTooltip(false)}
                        />
                        {showQuantityTooltip && (
                          <div className={styles.price_tooltip}>
                            Set the total number of copies available for
                            purchase. This cannot be changed after creation.
                          </div>
                        )}
                      </div>
                      <div className={styles.album_info}>
                        <input
                          type="number"
                          min="1"
                          placeholder="Enter quantity"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                        <div className={styles.quantity_indicator}>copies</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.mastering_container_options_bottom_bottom}
                  >
                    <div className={styles.datetime_group}>
                      <div className={styles.info_container}>
                        <span>Start Date</span>
                        <div className={styles.album_info}>
                          <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </div>
                      <div className={styles.info_container}>
                        <span>Start Time</span>
                        <div className={styles.album_info}>
                          <input
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.datetime_group}>
                      <div className={styles.info_container}>
                        <span>End Date</span>
                        <div className={styles.album_info}>
                          <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            min={
                              startDate ||
                              new Date().toISOString().split("T")[0]
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.info_container}>
                        <span>End Time</span>
                        <div className={styles.album_info}>
                          <input
                            type="time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <hr />

          {/* Benefits */}
          <div className={styles.benefits}>
            {file?.length > 0 || album?.length > 0 || video?.length > 0 ? (
              <h1>Benefits</h1>
            ) : null}

            <div className={styles.current_benefits}>
              {/* Albums */}
              {album?.length > 0 && (
                <>
                  <div className={styles.benefit_type}>
                    <AlbumIcon />
                    <span>{album.length === 1 ? 'Album' : 'Albums'}</span>
                  </div>
                  {album.map((singleAlbum, albumIndex) => (
                    <div key={albumIndex} className={styles.album_new}>
                      <div className={styles.album_information_video}>
                        <span>
                          <span className={styles.label}>Name: </span>
                          {truncate(singleAlbum?.name, 20, "...")}
                          {" | "}
                          <span className={styles.label}>Genre: </span>
                          {singleAlbum?.genre?.name}
                        </span>
                        <div className={styles.actions}>
                          <Button
                            size="sm"
                            onClick={() => {
                              setEditAlbum((prev) => !prev);
                              setEditAlbumId(singleAlbum.id);
                              setAlbumId(singleAlbum.id);
                            }}
                          >
                            {screenWidth < 600 ? "" : "Edit"}
                            <EditIcon fontSize="small" />
                          </Button>
                          {!wentLive && (
                            <DeleteForeverIcon
                              className={styles.delete_icon}
                              sx={{ color: pink[500] }}
                              onClick={() => {
                                setDeleteAlbum((prev) => !prev);
                                setAlbumId(singleAlbum.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {!expandedAlbums[albumIndex] ? (
                        <div
                          className={styles.arrow_down}
                          onClick={() => toggleAlbumContent(albumIndex)}
                        >
                          <ExpandMoreIcon />
                        </div>
                      ) : (
                        <div className={styles.album_content}>
                          {singleAlbum?.tracks?.map((track, index) => (
                            <div key={index} className={styles.album_track}>
                              <div className={styles.track_id_info}>
                                <div>{index + 1}.</div>
                                <span>{track?.track?.title}</span>
                              </div>
                              <audio
                                src={DirectusAudio(track?.track?.id)}
                                controls
                                loop
                                autoPlay={false}
                                className={styles.audio_player}
                                controlsList="nodownload noplaybackrate"
                              />
                            </div>
                          ))}
                          <div
                            className={styles.arrow_down}
                            onClick={() => toggleAlbumContent(albumIndex)}
                          >
                            <ExpandLessIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              {/* Videos */}
              {video?.length > 0 && (
                <>
                  <div className={styles.benefit_type}>
                    <OndemandVideoIcon />
                    <span>{video.length === 1 ? 'Video' : 'Videos'}</span>
                  </div>
                  {video.map((singleVideo, videoIndex) => (
                    <div key={videoIndex} className={styles.album_new}>
                      <div className={styles.album_information_video}>
                        <span>
                          <span className={styles.label}>Name: </span>
                          {singleVideo.name}
                        </span>
                        <div className={styles.actions}>
                          <Button
                            size="sm"
                            onClick={() => {
                              setEditVideo((prev) => !prev);
                              setVideoCollectionId(singleVideo?.id);
                            }}
                          >
                            {screenWidth < 600 ? "" : "Edit"}
                            <EditIcon fontSize="small" />
                          </Button>
                          {!wentLive && (
                            <DeleteForeverIcon
                              className={styles.delete_icon}
                              sx={{ color: pink[500] }}
                              onClick={() => {
                                setDeleteVideo((prev) => !prev);
                                setVideoCollectionId(singleVideo.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {!expandedVideos[videoIndex] ? (
                        <div
                          className={styles.arrow_down}
                          onClick={() => toggleVideoContent(videoIndex)}
                        >
                          <ExpandMoreIcon />
                        </div>
                      ) : (
                        <div className={styles.video_preview}>
                          <video controls width="100%">
                            <source
                              src={DirectusImage(singleVideo?.videos[0]?.video?.id)}
                            />
                            Your browser does not support the video tag.
                          </video>
                          <div
                            className={styles.arrow_down}
                            onClick={() => toggleVideoContent(videoIndex)}
                          >
                            <ExpandLessIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              {/* Files */}
              {file?.length > 0 && (
                <>
                  <div className={styles.benefit_type}>
                    <FolderIcon />
                    <span>{file.length === 1 ? 'File' : 'Files'}</span>
                  </div>
                  {file.map((singleFile, fileIndex) => (
                    <div key={fileIndex} className={styles.album_new}>
                      <div className={styles.album_information_video}>
                        <span>
                          <span className={styles.label}>Folder: </span>
                          {singleFile.name}
                        </span>
                        <div className={styles.actions}>
                          <Button
                            size="sm"
                            onClick={() => {
                              setEditFile((prev) => !prev);
                              setFileId(singleFile.id);
                            }}
                          >
                            {screenWidth < 600 ? "" : "Edit"}
                            <EditIcon fontSize="small" />
                          </Button>
                          {!wentLive && (
                            <DeleteForeverIcon
                              className={styles.delete_icon}
                              sx={{ color: pink[500] }}
                              onClick={() => {
                                setDeleteFile((prev) => !prev);
                                setFileId(singleFile.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {!expandedFiles[fileIndex] ? (
                        <div
                          className={styles.arrow_down}
                          onClick={() => toggleFileContent(fileIndex)}
                        >
                          <ExpandMoreIcon />
                        </div>
                      ) : (
                        <div className={styles.album_content}>
                          <div className={styles.file_folder}>
                            <FolderZipIcon />
                            <span>{singleFile?.files[0]?.file?.title}</span>
                          </div>
                          <div
                            className={styles.arrow_down}
                            onClick={() => toggleFileContent(fileIndex)}
                          >
                            <ExpandLessIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              {/* Add More Benefits */}
              {collectionBenefits?.map((benefit, index) => (
                <div
                  className={styles.create_benefit}
                  key={index}
                  onClick={() => {
                    hendleCreateBenefit(benefit);
                  }}
                >
                  <AddCircleIcon sx={{ color: '#2563eb' }} />
                  <span>Add {benefit.benefit}</span>
                </div>
              ))}
            </div>

            <hr />

            {/* Add Benefits Section */}
            {(collectionBenefits && collectionBenefits.length > 0) ||
            video ||
            album ||
            file ? (
              <div className={styles.add_benefit_header}>
                <AddCircleOutlineIcon />
                <span>Add benefits</span>
              </div>
            ) : (
              <div className={styles.select_benefit_message}>
                <span>Select your first benefit to share with your fans</span>
              </div>
            )}

            <div className={styles.benefits_container}>
              {benefits?.map((benefit) => (
                <div
                  className={styles.benefit}
                  key={benefit.id}
                  onClick={() => {
                    handleAddBenefit(benefit);
                  }}
                >
                  {benefit?.icon}
                  <span>{benefit?.benefit}</span>
                </div>
              ))}
            </div>
            <div className={styles.actions_container}>
              <button
                onClick={() => saveAsDraftAndConntinue()}
                disabled={!ready}
              >
                {loadingSaveAndExit ? (
                  <PulseLoader color="white" size={7} />
                ) : (
                  " Save as draft and exit"
                )}
              </button>
              <button
                onClick={() =>
                  status === "published"
                    ? editCollectionHandler()
                    : setStatusModal(true)
                }
                disabled={!ready}
              >
                {loading && status === "draft" ? (
                  "Publish Release"
                ) : loading && status === "published" ? (
                  <PulseLoader color="white" size={7} />
                ) : status === "draft" ? (
                  "Publish Release"
                ) : (
                  "Update Release"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.preview_side}>
        <PreviewImage
          edit
          image={collectionImagePreview}
          collectionName={collectionName}
        />
      </div>
      {statusModal ? (
        <Modal
          isOpen={true}
          onClose={() => {
            setStatusModal(false);
          }}
          title={"Live Status"}
          customSubClass="defaultModal"
        >
          <div className="defaultModalContent">
            <span>
              Going live with this release will prevent you from further
              edits. Do you want to continue?
            </span>
            <span style={{ fontSize: "14px", color: "gray" }}>
              These fields can always be edited: Start Date | End Date | Start
              Time | End Time | Status | Benefits
            </span>
            <div className="defaultModalButtons">
              <Button
                onClick={() => editCollectionHandler()}
                disabled={loading}
                loading={loading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
      {goLive ? (
        <Modal
          isOpen={true}
          onClose={() => {
            setGoLive(false);
            setFetchTrigger((prev) => !prev);
          }}
          title={""}
          center={true}
          customClass="cgModal"
        >
          <Confetti className="Confetti" recycle={recycle} />
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            Congratulations!, your Release will be live in a few minutes.
          </span>
          <Button onClick={() => handleVisitLivePage()}>
            View live collections
          </Button>
        </Modal>
      ) : null}

      {deleteAlbum || deleteVideo || deleteFile ? (
        <DeleteBenefitsModal
          deleteAlbum={deleteAlbum}
          setDeleteAlbum={setDeleteAlbum}
          deleteVideo={deleteVideo}
          setDeleteVideo={setDeleteVideo}
          deleteFile={deleteFile}
          setDeleteFile={setDeleteFile}
          setFetchTrigger={setFetchTrigger}
          setCollectionBenefits={setCollectionBenefits}
          cookie={cookie}
          videoCollectionId={videoCollectionId}
          albumId={albumId}
          fileId={fileId}
          setFileId={setFileId}
          setVideoCollectionId={setVideoCollectionId}
          setAlbumId={setAlbumId}
        />
      ) : null}

      {addSong || addVideo || addFile || editAlbum || editVideo || editFile ? (
        <AddBenefitsModal
          album={album}
          editAlbumId={editAlbumId}
          addSong={addSong}
          setAddSong={setAddSong}
          genres={genres}
          genre={genre}
          songFile={songFile}
          refSong={refSong}
          handleStatusGenre={handleStatusGenre}
          tracks={tracks}
          setTracks={setTracks}
          albumName={albumName}
          setAlbumName={setAlbumName}
          loading={loading}
          addVideo={addVideo}
          setAddVideo={setAddVideo}
          video={video}
          setVideo={setVideo}
          videoName={videoName}
          setVideoName={setVideoName}
          fansCollectionId={fansCollectionId}
          cookie={cookie}
          addFile={addFile}
          setAddFile={setAddFile}
          fileName={fileName}
          setFileName={setFileName}
          file={file}
          fileId={fileId}
          setFile={setFile}
          setFetchTrigger={setFetchTrigger}
          setCollectionBenefits={setCollectionBenefits}
          editAlbum={editAlbum}
          setEditAlbum={setEditAlbum}
          editVideo={editVideo}
          setEditVideo={setEditVideo}
          videoCollectionId={videoCollectionId}
          albumId={albumId}
          editFile={editFile}
          setEditFile={setEditFile}
          setGenre={setGenre}
        />
      ) : null}
    </div>
  );
}
