import { FiMusic, FiPlus } from 'react-icons/fi';
import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  onCreateAlbum: () => void;
}

export function EmptyState({ onCreateAlbum }: EmptyStateProps): JSX.Element {
  return (
    <div className={styles.emptyState}>
      <h2>Create your first content block</h2>
      <p>
      Showcase your work or promote your brand with engaging content blocks that reflect your creativity and connects with your fans.
      </p>
      <button className={styles.createButton} onClick={onCreateAlbum}>
        <FiPlus size={16} />
        Create Content Block
      </button>
    </div>
  );
}
