import { useState } from "react";
import styles from "./Album.module.scss";
import { DirectusImage } from "app/core/common";
import { FiEdit3, FiTrash } from "react-icons/fi";

interface AlbumImage {
  id: string;
  directus_files_id: {
    id: string;
  };
  featured: boolean;
}

interface AlbumImages {
  id: string;
  images: AlbumImage[];
}

interface Album {
  id: string;
  name: string;
  desc: string;
  access_type: "public" | "private";
  content_type: "gallery" | "video" | "article";
  gallery: AlbumImages;
  logo?: string;
  location?: string;
  discount?: number;
}

interface AlbumBlockProps {
  albumBlock: any;
  onEdit?: (album: Album) => void;
  onDelete?: (album: Album) => void;
  onImageClick?: (imageUrl: string) => void;
}

export function AlbumBlock({ albumBlock, onEdit, onDelete, onImageClick }: AlbumBlockProps) {
  const handleImageClick = (imageId: string) => {
    if (onImageClick) {
      onImageClick(DirectusImage(imageId));
    }
  };

  return (
    <div className={styles.albumBlockContainer}>
      <div className={styles.albumHeader}>
        <div className={styles.albumInfo}>
          <h3 className={styles.albumTitle}>{albumBlock.name}</h3>
          <p className={styles.albumDesc}>{albumBlock.desc}</p>
        </div>
        <div className={styles.albumActions}>
          {/* {onEdit && (
            <button
              className={styles.editButton}
              onClick={() => onEdit(albumBlock)}
              aria-label="Edit album"
            >
              <FiEdit3 size={16} />
              Edit
            </button>
          )} */}
          {onDelete && (
            <button
              className={styles.deleteButton}
              onClick={() => onDelete(albumBlock)}
              aria-label="Delete album"
            >
              <FiTrash size={16} />
              Delete
            </button>
          )}
        </div>
      </div>
      <div className={styles.imageGrid}>
        {albumBlock.gallery?.images?.slice(0, 3).map((image, index) => (
          <div 
            key={image.id} 
            className={`${styles.imageContainer} ${
              index === 2 && albumBlock.gallery.images.length > 3 ? styles.hasMore : ''
            }`}
            onClick={() => handleImageClick(image.directus_files_id.id)}
          >
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${DirectusImage(image.directus_files_id)})`,
              }}
            />
            {index === 2 && albumBlock.gallery.images.length > 3 && (
              <div className={styles.moreImages}>
                +{albumBlock.gallery.images.length - 3} more
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
