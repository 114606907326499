import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./sidebar/Sidebar.module.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const mainContent = document.querySelector(`.${styles.mainContent}`);
    if (mainContent) {
      mainContent.scrollTo({
        top: 0,
        behavior: "auto"
      });
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;