import React, { useEffect, useState } from "react";
import styles from "./Studio.module.scss";
import { options } from "./StudioOptions";
import StudioOptionBox from "./StudioOptionBox";
import StudioAnalytics from "./StudioAnalytics";
import Modal from "app/components/Modal";
import Button from "app/components/button/button";
import { useHook } from "app/hooks/common";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchBalance,
  fetchPymentHistory,
  requestPayout,
} from "app/helpers/payment";

export default function Studio() {
  const [openPayout, setOpenPayout] = useState<boolean>(false);
  const [paymentOption, setPaymentOption] = useState<string>("");
  const [paypalEmail, setPaypalEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [whatsappNumber, setWhatsappNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [pendingPayment, setPendingPayment] = useState<boolean>(false);
  const [showPayments, setShowPayments] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [paymentHistory, setPaymentHistory] = useState<Array<any>>([]);
  const [notPayment, setNotPayment] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const { address, cookie, userInfo } = useHook();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const balanceData = await fetchBalance(cookie);
        setBalance(balanceData?.data?.balance);

        const payment_history = await fetchPymentHistory(cookie);
        setPaymentHistory(payment_history?.data);

        if (
          payment_history?.data.some((payment) => payment.status === "pending")
        ) {
          setPendingPayment(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [cookie, pendingPayment]);

  const handleOpenPayout = () => {
    if (balance < 20 || balance === undefined || pendingPayment) {
      setNotPayment(true);
    } else {
      setOpenPayout(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleRequestPayout = async () => {
    if (!paypalEmail || !fullName || !amount || !whatsappNumber) {
      setError(
        "Please provide all required information and ensure amount is at least $20."
      );
      return;
    }

    if (!validateEmail(paypalEmail)) {
      setError("Please provide a valid PayPal email.");
      return;
    }

    setError("");
    try {
      setLoading(true);
      await requestPayout(
        cookie,
        amount,
        paymentOption,
        paypalEmail,
        fullName,
        whatsappNumber
      );
      setPendingPayment(true);
      setOpenPayout(false);
    } catch (error) {
      console.error(error);
      setError("Failed to process payout request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  /* if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress size={48} thickness={4} />
        <p>Loading your studio...</p>
      </div>
    );
  } */

  return (
    <div className={styles.container}>
      {/* <div className={styles.title_request}>
        <h1>Welcome to your Studio</h1>
        {userInfo && (
          <>
            <p>Current Balance: ${Number(balance).toFixed(2) || "0.00"}</p>

            <Tooltip
              title={
                balance < 20
                  ? "Minimum payout amount is $20"
                  : pendingPayment
                  ? "You have a pending payment"
                  : ""
              }
            >
              <div>
                <button
                  className={
                    balance! >= 20 && !pendingPayment
                      ? styles.payment_on
                      : styles.payment_off
                  }
                  onClick={handleOpenPayout}
                >
                  Request Payout
                </button>
              </div>
            </Tooltip>
          </>
        )}
      </div> */}

      {userInfo && (
        <div className={styles.payment_section}>
          <StudioAnalytics />
          <div
            className={styles.payment_header}
            onClick={() => setShowPayments(!showPayments)}
          >
            <div className={styles.header_content}>
              <h2>Payout History</h2>
              {paymentHistory && paymentHistory.length > 0 && (
                <div className={styles.payment_count}>
                  {paymentHistory.length}{" "}
                  {paymentHistory.length === 1 ? "transaction" : "transactions"}
                </div>
              )}
            </div>
            <div className={styles.toggle_icon}>
              {showPayments ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </div>
          </div>

          {showPayments && (
            <div className={styles.payment_table_container}>
              {paymentHistory && paymentHistory.length > 0 ? (
                <table className={styles.payment_table}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Payment Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistory.map((payment, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(payment.date_requested).toLocaleDateString(
                            undefined,
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </td>
                        <td className={styles.amount_cell}>
                          ${Number(payment.amount).toFixed(2)}
                        </td>
                        <td>
                          <span
                            className={`${styles.status_badge} ${
                              styles[payment.status]
                            }`}
                          >
                            {payment.status}
                          </span>
                        </td>
                        <td>{payment.platform}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className={styles.no_payments}>
                  <p>No payment history available</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.studio_section} id="studio_options">
        <h2 className={styles.section_title}>Studio Options</h2>
        <div className={styles.options_container}>
          {options.map((option, index) => (
            <Tooltip
              key={index}
              title={option.available ? option.message : "Coming Soon"}
              placement="top"
              arrow
            >
              <div className={styles.option_wrapper}>
                <StudioOptionBox option={option} />
                {option.category === "coming_soon" && (
                  <span className={styles.coming_soon_badge}>Coming Soon</span>
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>

      <Modal
        isOpen={openPayout}
        onClose={() => {
          setOpenPayout(false);
          setError("");
        }}
        title="Request Payout"
      >
        <div className={styles.form}>
          <label>Amount (minimum $20)</label>
          <input
            type="number"
            min="20"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
          />

          <label>Payment Method</label>
          <select
            value={paymentOption}
            onChange={(e) => setPaymentOption(e.target.value)}
          >
            <option value="">Select payment method</option>
            <option value="paypal">PayPal</option>
          </select>

          <label>PayPal Email</label>
          <input
            type="email"
            value={paypalEmail}
            onChange={(e) => setPaypalEmail(e.target.value)}
            placeholder="Enter PayPal email"
          />

          <label>Full Name</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter your full name"
          />

          <label>WhatsApp Number</label>
          <input
            type="text"
            value={whatsappNumber}
            onChange={(e) => setWhatsappNumber(e.target.value)}
            placeholder="Enter WhatsApp number"
          />

          {error && <div className={styles.error}>{error}</div>}

          <Button
            onClick={handleRequestPayout}
            disabled={loading}
            className={styles.marginTop20}
          >
            {loading ? <CircularProgress size={20} /> : "Request Payout"}
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={notPayment}
        onClose={() => setNotPayment(false)}
        title="Cannot Request Payout"
      >
        <div className={styles.form}>
          <p>
            {balance < 20
              ? "You need a minimum balance of $20 to request a payout."
              : pendingPayment
              ? "You have a pending payment. Please wait for it to be processed."
              : "Something went wrong. Please try again later."}
          </p>
          <Button onClick={() => setNotPayment(false)}>Close</Button>
        </div>
      </Modal>
    </div>
  );
}
