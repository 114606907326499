import Cookie from "js-cookie";
import useConnectWallet from "app/Loop_Reuseable_Component/packages/loop_wallet/hooks/useConnectWallet";
import { useUser } from "app/context/userInfo";
import { isCreator } from "app/core/common";
import { useWallet } from "@app/Loop_Reuseable_Component/packages/loop_wallet";

export const checkUsername = (username: string) => {
  return username.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '');
}
  

export const useHook = () => {
  const { address, cookie } = useWallet();
  const {
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
    updateUserInfo,
  } = useUser();
  const _isCreator = isCreator(userInfo?.role);

  return {
    address,
    cookie,
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    isCreator: _isCreator,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
    updateUserInfo,
  };
};
