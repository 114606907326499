import { useState, useCallback, useEffect } from "react";
import styles from "./ContentBlocks.module.scss";
import editStyles from "./EditBlock.module.scss";
import deleteStyles from "./DeleteDialog.module.scss";
import {
  FiPlus,
  FiEdit3,
  FiTrash,
  FiImage,
  FiMapPin,
  FiArrowRight,
  FiX,
  FiLayout,
} from "react-icons/fi";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useHook } from "app/hooks/common";
import { ContentBlockForm, ContentBlockFormData } from "./ContentBlocksForm";
import { DirectusImage } from "app/core/common";
import { EmptyState } from "./EmptyState";
import { YoutubeBlock } from "./Youtube";
import { AlbumBlock } from "./Album";
import { DomainConfig, useDomainConfig } from "app/context/domainConfigContext";

interface AlbumImage {
  id: string;
  directus_files_id: {
    id: string;
  };
  featured: boolean;
}

interface AlbumImages {
  id: string;
  images: AlbumImage[];
}

interface Album {
  id: string;
  name: string;
  desc: string;
  access_type: "public" | "private";
  content_type: "gallery" | "video" | "article";
  gallery: AlbumImages;
  logo?: string;
  location?: string;
  discount?: number;
}

interface ContentBlock {
  id: string;
  blocks: {
    id: string;
    order: number;
    date_created: number;
    collection: string;
    item: any;
  }[];
}

interface VideoDetails {
  id: string;
  title: string;
  thumbnails: {
    default: { url: string; width: number; height: number };
    medium: { url: string; width: number; height: number };
    high: { url: string; width: number; height: number };
  };
}

interface EditingBlock {
  type: "gallery" | "video" | "article";
  data: any;
  blockId: string;
}

interface DeleteDialogState {
  open: boolean;
  blockId: string | null;
  blockType: string;
}

export function ContentBlocks() {
  const {
    domainConfig,
    loading: fetchingDomainConfig,
    fetchDomainConfig
  } = useDomainConfig();
  const { cookie, userInfo } = useHook();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [editingBlock, setEditingBlock] = useState<EditingBlock | null>(null);
  const [contentBlocks, setContentBlocks] = useState<ContentBlock | null>(null);
  const [videoDetails, setVideoDetails] = useState<{
    [key: string]: VideoDetails;
  }>({});
  const [selectedBlockType, setSelectedBlockType] = useState<
    "gallery" | "video" | "article" | null
  >(null);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    open: false,
    blockId: null,
    blockType: "",
  });

  useEffect(() => {
    fetchDomainConfig();
  }, []);

  /* ====================== */
  /*  Fetch Content Blocks  */
  /* ======== Start ======= */
  async function fetchContentBlocks() {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE}wl/content_blocks/${domainConfig?.id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        }
      );
      const data = await response.json();
      setContentBlocks(data);
    } catch (error) {
      console.error("Error fetching content blocks:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (userInfo?.id && domainConfig?.id) fetchContentBlocks();
  }, [userInfo, domainConfig]);
  /* ======== End ======= */
  /* ==================== */

  const enableContentBlocks = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE}wl/content_blocks/${domainConfig?.id}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        }
      );
      const data = await response.json();
      if (data) {
        setContentBlocks(data);
      }
    } catch (error) {
      console.error("Error enabling content blocks:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateGallery = useCallback(() => {
    setEditingBlock(null);
    setShowForm(true);
  }, []);

  const createContentBlock = async (data: ContentBlockFormData) => {
    console.log({ data });
    const response = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE}wl/blocks/${data.content_type}`,
      {
        method: "POST",
        credentials: "include", // This ensures cookies are sent with the request
        headers: {
          "Content-Type": "application/json",
          user_cookie: cookie,
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  };

  const handleSubmit = async (data: ContentBlockFormData): Promise<void> => {
    setLoading(true);

    try {
      // Refetch content blocks after successful submission
      await fetchContentBlocks();

      setShowForm(false);
      setSelectedBlockType(null);
    } catch (error) {
      console.error("Error submitting content:", error);
      // Revert optimistic update on error
      /* setContentBlocks(prev => ({
        ...prev!,
        blocks: prev!.blocks.filter(block => !block.id.startsWith('temp-'))
      })); */
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (blockType: "gallery" | "video" | "article", blockData: any, blockId: string) => {
    setEditingBlock({
      type: blockType,
      data: blockData,
      blockId: blockId
    });
  };

  const handleEditSubmit = async (data: ContentBlockFormData): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE}wl/blocks/${editingBlock?.blockId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update block');
      }

      await fetchContentBlocks();
      setEditingBlock(null);
    } catch (error) {
      console.error("Error updating content:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteContentBlock = async (blockId: string) => {
    console.log({ blockId, contentId: contentBlocks?.id });
    const response = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE}blocks/${blockId}?contentId=${contentBlocks?.id}`,
      {
        method: "DELETE",
        credentials: "include", // This ensures cookies are sent with the request
        headers: {
          "Content-Type": "application/json",
          user_cookie: cookie,
        }
      }
    );
    return response.json();
  };

  const handleDeleteClick = (blockId: string, blockType: string) => {
    setDeleteDialog({
      open: true,
      blockId,
      blockType
    });
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({
      open: false,
      blockId: null,
      blockType: ""
    });
  };

  const handleDeleteConfirm = async () => {
    if (!deleteDialog.blockId) return;

    try {
      setLoading(true);
      handleDeleteCancel(); // Close dialog immediately
      const response = await deleteContentBlock(deleteDialog.blockId);
      
      if (response.message === "Content block deleted successfully") {
        // Update UI by removing the deleted block
        setContentBlocks(prev => {
          if (!prev) return null;
          return {
            ...prev,
            blocks: prev.blocks.filter(block => block.id !== deleteDialog.blockId)
          };
        });
      }
    } catch (error) {
      console.error("Error deleting content block:", error);
    } finally {
      setLoading(false);
    }
  };

  if (editingBlock) {
    return (
      <div className={editStyles.editOverlay}>
        <div className={editStyles.editContainer}>
          <button 
            className={editStyles.closeButton}
            onClick={() => setEditingBlock(null)}
          >
            <FiX size={20} />
          </button>
          <ContentBlockForm
            contentBlockId={contentBlocks?.id}
            initialData={{
              ...editingBlock.data,
              content_type: editingBlock.type, // Pass the type but don't allow changing it
              name: editingBlock.data.name,
              description: editingBlock.data.desc,
            }}
            onSubmit={handleEditSubmit}
            onCancel={() => setEditingBlock(null)}
            isLoading={loading}
            contentType={editingBlock.type}
            isEditing={true}
          />
        </div>
      </div>
    );
  }

  if (showForm) {
    return (
      <ContentBlockForm
        contentBlockId={contentBlocks?.id}
        onSubmit={handleSubmit}
        onCancel={() => {
          setShowForm(false);
          setEditingBlock(null);
          setSelectedBlockType(null);
        }}
        isLoading={loading}
        contentType={selectedBlockType}
      />
    );
  }

  if (fetchingDomainConfig) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (!contentBlocks?.id && !loading) {
    return (
      <div className={styles.container}>
        <div className={styles.emptyState}>
          <FiLayout className={styles.icon} size={64} />
          <h2>Content Blocks Not Enabled</h2>
          <p>
            Content blocks allow you to showcase your content in a beautiful and organized way. 
            Enable content blocks to start creating galleries, video collections, and more.
          </p>
          <button 
            className={styles.enableButton}
            onClick={enableContentBlocks}
          >
            Enable Content Blocks
            <FiArrowRight size={18} />
          </button>
        </div>
      </div>
    );
  }

  if (contentBlocks?.blocks?.length === 0) {
    return (
      <div className={styles.container}>
        <EmptyState onCreateAlbum={handleCreateGallery} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        className={deleteStyles.dialog}
      >
        <DialogTitle id="delete-dialog-title" className={deleteStyles.title}>
          Delete {deleteDialog.blockType}
        </DialogTitle>
        <DialogContent className={deleteStyles.content}>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this {deleteDialog.blockType.toLowerCase()}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={deleteStyles.actions}>
          <button 
            onClick={handleDeleteCancel} 
            className={deleteStyles.cancelButton}
          >
            Cancel
          </button>
          <button 
            onClick={handleDeleteConfirm}
            className={deleteStyles.deleteButton}
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete"}
          </button>
        </DialogActions>
      </Dialog>

      <div className={styles.header}>
        <div>
          <h1>Content Blocks</h1>
          <p>Create and manage your content blocks</p>
          <small>Content Block ID: {contentBlocks?.id}</small>
        </div>
        {userInfo?.id && !loading && (
          <button
            className={styles.createButton}
            onClick={() => setShowForm(true)}
          >
            <FiPlus size={16} />
            Create Content
          </button>
        )}
      </div>

      {loading ? (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      ) : !contentBlocks && !loading ? (
        <EmptyState onCreateAlbum={handleCreateGallery} />
      ) : (
        <div className={styles.galleriesGrid}>
          {contentBlocks?.blocks
            ?.sort((a, b) => {
              // Handle potential undefined values and convert string IDs to numbers
              const dateA = a.id ? parseInt(a.id) : 0;
              const dateB = b.id ? parseInt(b.id) : 0;
              return dateB - dateA; // Sort in descending order (newest first)
            })
            ?.map((block, index) => {
            console.log({ block });
            switch (block.collection) {
              case "youtube_block":
                return (
                  <YoutubeBlock
                    key={`youtube_block-${block.id}`}
                    youtubeBlock={[block?.item]}
                    loading={loading}
                    transitionIn={false}
                    onEdit={() => handleEdit("video", block.item, block.id)}
                    onDelete={() => handleDeleteClick(block.id, "video")}
                  />
                );
              case "album":
                return (
                  <AlbumBlock
                    key={`album-${block.id}`}
                    albumBlock={block?.item}
                    onImageClick={() => {}}
                    onEdit={() => handleEdit("gallery", block.item, block.id)}
                    onDelete={() => handleDeleteClick(block.id, "gallery")}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
}
