import React, { useState } from 'react';
import styles from './PlansManagement.module.scss';

interface Plan {
  id: string;
  name: string;
  price: number;
  billingPeriod: 'monthly' | 'yearly';
  features: string[];
  isActive: boolean;
}

interface Subscriber {
  id: string;
  name: string;
  email: string;
  planId: string;
  subscriptionDate: string;
  status: 'active' | 'cancelled' | 'expired';
}

const PlansManagement: React.FC = () => {
  // Sample data - replace with real data from API
  const [plans, setPlans] = useState<Plan[]>([
    {
      id: '1',
      name: 'Basic',
      price: 9.99,
      billingPeriod: 'monthly',
      features: ['Access to basic content', 'Standard streaming quality', 'Email support'],
      isActive: true,
    },
    {
      id: '2',
      name: 'Premium',
      price: 19.99,
      billingPeriod: 'monthly',
      features: ['Access to all content', 'HD streaming quality', 'Priority support', 'Exclusive updates'],
      isActive: true,
    },
  ]);

  const [subscribers, setSubscribers] = useState<Subscriber[]>([
    {
      id: '1',
      name: 'John Doe',
      email: 'john@example.com',
      planId: '2',
      subscriptionDate: '2024-01-01',
      status: 'active',
    },
  ]);

  const [activeTab, setActiveTab] = useState<'plans' | 'subscribers'>('plans');
  const [showNewPlanModal, setShowNewPlanModal] = useState(false);

  const handlePlanStatusToggle = (planId: string) => {
    setPlans(plans.map(plan => 
      plan.id === planId ? { ...plan, isActive: !plan.isActive } : plan
    ));
  };

  return (
    <div className={styles.plansManagement}>
      <div className={styles.header}>
        <h1>Plans & Subscriptions</h1>
        <div className={styles.actions}>
          <button 
            className={styles.primaryButton}
            onClick={() => setShowNewPlanModal(true)}
          >
            Create New Plan
          </button>
        </div>
      </div>

      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'plans' ? styles.active : ''}`}
            onClick={() => setActiveTab('plans')}
          >
            Plans
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'subscribers' ? styles.active : ''}`}
            onClick={() => setActiveTab('subscribers')}
          >
            Subscribers
          </button>
        </div>
      </div>

      {activeTab === 'plans' ? (
        <div className={styles.plansGrid}>
          {plans.map(plan => (
            <div key={plan.id} className={styles.planCard}>
              <div className={styles.planHeader}>
                <h3>{plan.name}</h3>
                <div className={styles.price}>
                  ${plan.price}
                  <span className={styles.period}>/{plan.billingPeriod}</span>
                </div>
              </div>
              <div className={styles.planFeatures}>
                {plan.features.map((feature, index) => (
                  <div key={index} className={styles.feature}>
                    <span className={styles.checkmark}>✓</span>
                    {feature}
                  </div>
                ))}
              </div>
              <div className={styles.planActions}>
                <button 
                  className={`${styles.statusToggle} ${plan.isActive ? styles.active : ''}`}
                  onClick={() => handlePlanStatusToggle(plan.id)}
                >
                  {plan.isActive ? 'Active' : 'Inactive'}
                </button>
                <button className={styles.editButton}>
                  Edit Plan
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.subscribersTable}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Plan</th>
                <th>Subscription Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map(subscriber => (
                <tr key={subscriber.id}>
                  <td>{subscriber.name}</td>
                  <td>{subscriber.email}</td>
                  <td>{plans.find(p => p.id === subscriber.planId)?.name}</td>
                  <td>{new Date(subscriber.subscriptionDate).toLocaleDateString()}</td>
                  <td>
                    <span className={`${styles.status} ${styles[subscriber.status]}`}>
                      {subscriber.status}
                    </span>
                  </td>
                  <td>
                    <button className={styles.actionButton}>
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PlansManagement;
