import React, { useEffect, useState } from 'react';
import styles from './StudioAnalytics.module.scss';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FiDollarSign } from 'react-icons/fi';
import { fetchBalance } from 'app/helpers/payment';
import { useHook } from 'app/hooks/common';
import { fetchUserFollowerList } from 'app/api/profile.api';
import { set } from 'numeral';
import { useHistory } from 'react-router-dom';

export default function StudioAnalytics() {
  const { cookie, userInfo } = useHook();
  const history = useHistory();
  const [showWebAnalytics, setShowWebAnalytics] = useState<boolean>(false);
  const [isRequestingPayout, setIsRequestingPayout] = useState(false);
  const [dashboardStats, setDashboardStats] = useState({
    totalFans: 0,
    engagementRate: 0,
    sales: 0,
    revenue: 0,
  });

  const handleRequestPayout = async () => {
    setIsRequestingPayout(true);
    try {
      // TODO: Implement payout request API
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
      // Show success message or handle response
    } catch (error) {
      // Handle error
      console.error('Error requesting payout:', error);
    } finally {
      setIsRequestingPayout(false);
    }
  };

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const balance = await fetchBalance(cookie);
        // Update analytics data here
        setDashboardStats((prev) => ({
          ...prev,
          revenue: balance?.data?.balance,
        }));
      } catch (error) {
        console.error('Error fetching analytics:', error);
      }
    };

    const fetchFollowers = async () => {
      try {
        const response = await fetchUserFollowerList({ userId: userInfo.id, page: 1, limit: -1 });
        // Update analytics data here
        setDashboardStats((prev) => ({
          ...prev,
          totalFans: response?.length,
        }));
      } catch (error) {
        console.error('Error fetching followers:', error);
      }
    };

    const fetchSales = async () => {
      try {
        // Update analytics data here
        setDashboardStats((prev) => ({
          ...prev,
          sales: 0,
        }));
      } catch (error) {
        console.error('Error fetching sales:', error);
      }
    };

    fetchAnalytics();
    fetchFollowers();
    fetchSales();
  }, [cookie]);

  return (
    <div className={styles.analytics_container}>
      <div className={styles.analytics_header}>
        <h2>Welcome to your Studio</h2>
        <p>Track your content performance and audience engagement</p>
        <button className={styles.manage_website} onClick={() => history.push('/domain/settings')}>
          Manage Website
        </button>
      </div>
      
      <div className={styles.analytics_grid}>
        <div className={styles.analytics_card}>
          <h3>Total Fans</h3>
          <div className={styles.analytics_value}>{dashboardStats.totalFans || 0}</div>
        </div>
        
        {/* <div className={styles.analytics_card}>
          <h3>Engagement Rate</h3>
          <div className={styles.analytics_value}>0%</div>
        </div> */}
        
        <div className={styles.analytics_card}>
          <h3>Sales</h3>
          <div className={styles.analytics_value}>{dashboardStats.sales || 0}</div>
        </div>
        
        <div className={styles.analytics_card}>
          <h3>Revenue</h3>
          <div className={styles.analytics_value}>${dashboardStats.revenue || 0}</div>
          {/* <button 
            className={styles.payout_button}
            onClick={handleRequestPayout}
            disabled={isRequestingPayout}
          >
            <FiDollarSign />
            {isRequestingPayout ? 'Requesting...' : 'Request Payout'}
          </button> */}
        </div>
      </div>

      <div className={styles.web_analytics_section}>
        {/* <div 
          className={styles.web_analytics_header}
          onClick={() => setShowWebAnalytics(!showWebAnalytics)}
        >
          <div className={styles.header_content}>
            <h3>Website Analytics</h3>
            <p>View detailed website performance metrics</p>
          </div>
          {showWebAnalytics ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </div> */}

        {showWebAnalytics && (
          <div className={styles.web_analytics_grid}>
            <div className={styles.analytics_card}>
              <h3>Page Views</h3>
              <div className={styles.analytics_value}>0</div>
            </div>
            
            <div className={styles.analytics_card}>
              <h3>Bandwidth Used</h3>
              <div className={styles.analytics_value}>0 MB</div>
            </div>
            
            <div className={styles.analytics_card}>
              <h3>Avg. Time on Page</h3>
              <div className={styles.analytics_value}>0m</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
