import React from 'react';
import styles from './AnalyticsOverview.module.scss';

interface AnalyticsData {
  pageViews: number;
  uniqueVisitors: number;
  averageTimeOnPage: string;
  bounceRate: string;
  topPages?: Array<{ page: string; views: number }>;
  trafficSources?: Array<{ source: string; percentage: number }>;
}

interface AnalyticsOverviewProps {
  title: string;
  data: AnalyticsData;
}

export const AnalyticsOverview: React.FC<AnalyticsOverviewProps> = ({ title, data }) => {
  const metrics = [
    {
      label: 'Page Views',
      value: data.pageViews.toLocaleString(),
      icon: '👁️'
    },
    {
      label: 'Unique Visitors',
      value: data.uniqueVisitors.toLocaleString(),
      icon: '👤'
    },
    {
      label: 'Avg. Time on Page',
      value: data.averageTimeOnPage,
      icon: '⏱️'
    },
    {
      label: 'Bounce Rate',
      value: data.bounceRate,
      icon: '↩️'
    }
  ];

  return (
    <div className={styles.analyticsOverview}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.metricsGrid}>
        {metrics.map((metric, index) => (
          <div key={index} className={styles.metricCard}>
            <div className={styles.metricIcon}>{metric.icon}</div>
            <div className={styles.metricContent}>
              <span className={styles.metricLabel}>{metric.label}</span>
              <span className={styles.metricValue}>{metric.value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
