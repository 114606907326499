import React from 'react';
import styles from './Analytics.module.scss';
import { AnalyticsOverview } from '../../components/AnalyticsOverview/AnalyticsOverview';

const Analytics: React.FC = () => {
  // Sample data - replace with real data from API
  const analyticsData = {
    pageViews: 12500,
    uniqueVisitors: 8300,
    averageTimeOnPage: '2m 45s',
    bounceRate: '35%',
    topPages: [
      { page: '/home', views: 5200 },
      { page: '/collections', views: 3100 },
      { page: '/about', views: 2100 }
    ],
    trafficSources: [
      { source: 'Direct', percentage: 40 },
      { source: 'Social', percentage: 35 },
      { source: 'Search', percentage: 25 }
    ]
  };

  return (
    <div className={styles.analyticsPage}>
      <div className={styles.header}>
        <h1>Analytics Dashboard</h1>
        <p>Track your website's performance and visitor insights</p>
      </div>
      
      <div className={styles.overviewSection}>
        <AnalyticsOverview 
          title="Overview Metrics"
          data={analyticsData}
        />
      </div>

      <div className={styles.detailedMetrics}>
        <div className={styles.metricsCard}>
          <h2>Top Pages</h2>
          <div className={styles.pagesList}>
            {analyticsData.topPages.map((page, index) => (
              <div key={index} className={styles.pageItem}>
                <span className={styles.pagePath}>{page.page}</span>
                <span className={styles.pageViews}>{page.views.toLocaleString()} views</span>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.metricsCard}>
          <h2>Traffic Sources</h2>
          <div className={styles.sourcesList}>
            {analyticsData.trafficSources.map((source, index) => (
              <div key={index} className={styles.sourceItem}>
                <span className={styles.sourceName}>{source.source}</span>
                <div className={styles.sourceBar}>
                  <div 
                    className={styles.sourceProgress} 
                    style={{ width: `${source.percentage}%` }}
                  />
                </div>
                <span className={styles.sourcePercentage}>{source.percentage}%</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
