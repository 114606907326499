import { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { FiEdit3, FiTrash } from "react-icons/fi";
import styles from "./Youtube.module.scss";

interface YoutubeBlockProps {
  youtubeBlock: Array<{
    title: string;
    embeds?: Array<{
      embed_id: string;
    }>;
  }>;
  loading?: boolean;
  transitionIn?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

// YouTube provides several thumbnail options:
// maxresdefault.jpg (1280x720)
// sddefault.jpg (640x480)
// hqdefault.jpg (480x360)
// mqdefault.jpg (320x180)
// default.jpg (120x90)
const getYoutubeThumbnail = (videoId: string, quality: 'maxres' | 'sd' | 'hq' | 'mq' | 'default' = 'hq') => {
  return `https://img.youtube.com/vi/${videoId}/${quality}default.jpg`;
};

export function YoutubeBlock({ youtubeBlock, loading, transitionIn, onEdit, onDelete }: YoutubeBlockProps) {
  const [activeVideo, setActiveVideo] = useState<string | null>(null);

  const handleVideoClick = (embedId: string) => {
    setActiveVideo(embedId);
  };

  return (
    <div className={styles.youtubeBlockContainer}>
      <div className={styles.youtubeHeader}>
        <h3 className={styles.youtubeTitle}>
          {youtubeBlock?.[0]?.title || '...'}
        </h3>
        <div className={styles.youtubeActions}>
          {/* {onEdit && (
            <button
              className={`${styles.editButton}`}
              onClick={onEdit}
              aria-label="Edit youtube block"
            >
              <FiEdit3 size={16} />
              Edit
            </button>
          )} */}
          {onDelete && (
            <button
              className={`${styles.deleteButton}`}
              onClick={onDelete}
              aria-label="Delete youtube block"
            >
              <FiTrash size={16} />
              Delete
            </button>
          )}
        </div>
      </div>
      <div className={`${styles.youtubeBlock} ${transitionIn ? styles.transitionIn : ''}`}>
        {youtubeBlock?.[0]?.embeds?.map((embed, embedIndex) => {
          return (
            <div 
              key={`embed-${embedIndex}`} 
              className={styles.videoCard}
              onClick={() => handleVideoClick(embed.embed_id)}
            >
              <div className={styles.videoWrapper}>
                {activeVideo === embed.embed_id ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${embed.embed_id}?autoplay=1`}
                    title={`${youtubeBlock[0]?.title} - video ${embedIndex + 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                ) : (
                  <>
                    <img
                      src={getYoutubeThumbnail(embed.embed_id)}
                      alt={`Video ${embedIndex + 1}`}
                      className={styles.thumbnail}
                      onError={(e) => {
                        // If high quality thumbnail fails, fall back to medium quality
                        const img = e.target as HTMLImageElement;
                        if (img.src.includes('hqdefault')) {
                          img.src = getYoutubeThumbnail(embed.embed_id, 'sd');
                        }
                      }}
                    />
                    <div className={styles.playIcon} />
                  </>
                )}
              </div>
            </div>
          );
        })}
        {loading && (
          <div className={styles.loading}>
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </div>
  );
}
