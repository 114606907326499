import React, { useState, useCallback, useEffect } from "react";
import styles from "./WebsiteManagement.module.scss";
import { DirectusImage } from "app/core/common";
import { useHook } from "app/hooks/common";
import { ChromePicker } from "react-color";
import { CircularProgress } from "@mui/material";
import {
  FiGlobe,
  FiImage,
  FiDroplet,
  FiSearch,
  FiShare2,
  FiCheck,
  FiClock,
  FiUpload,
  FiInfo,
  FiSave,
  FiSun,
  FiMoon,
  FiX,
  FiZoomIn,
  FiZoomOut,
  FiFacebook,
  FiInstagram,
  FiYoutube,
  FiLinkedin,
  FiExternalLink,
  FiSquare,
  FiMaximize2,
  FiMinimize2,
  FiSend
} from "react-icons/fi";
import { RiTwitterXLine } from "react-icons/ri";
import { SiSpotify, SiTiktok } from "react-icons/si";
import * as api from "app/api/collections.api";
import { DomainConfig, useDomainConfig } from "app/context/domainConfigContext";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import axios from "axios";
import Button from "@app/components/button/button";
import { useToast } from "@app/components/Toast/ToastContext";

interface State {
  loading: { [key: string]: boolean };
  crop: Point;
  zoom: number;
  croppedAreaPixels: Area | null;
  showCropper: boolean;
  tempBannerFile: File | null;
  tempBannerUrl: string | null;
  tempLogoUrl: string | null;
  showLogoPreview: boolean;
  domainError: string | null;
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0
): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
}

export default function WebsiteManagement() {
  const { cookie } = useHook();
  const {
    domainConfig,
    loading: fetchingDomainConfig,
    updateDomainConfig,
    fetchDomainConfig
  } = useDomainConfig();
  const toast = useToast();
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [domainOptions, setDomainOptions] = useState<DomainConfig>({
    ...domainConfig,
  });
  const [showColorPicker, setShowColorPicker] = useState({
    primary: false,
    secondary: false,
  });
  const initialState: State = {
    showCropper: false,
    tempBannerFile: null,
    tempBannerUrl: null,
    tempLogoUrl: null,
    showLogoPreview: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
    domainError: null,
    loading: { create: false },
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setDomainOptions({ ...domainConfig });
  }, [domainConfig]);

  const handleDomainChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((prev) => ({ ...prev, domainError: null }));
      const value = e.target.value
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "")
        .replace(/^-+|-+$/g, "");

      setDomainOptions((prev) => ({
        ...prev,
        domain: `${value}.loop.fans`,
      }));
    },
    []
  );

  const handleCreateDomain = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, domainError: null }));
      setLoading((prev) => ({ ...prev, create: true }));

      const domainName = domainOptions.domain?.split(".")[0];
      if (!domainName || domainName.length < 3) {
        setState((prev) => ({
          ...prev,
          domainError: "Domain name must be at least 3 characters long",
        }));
        return;
      }

      // Create new domain
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE}wl/domain`,
        {
          domain: `${domainOptions.domain}`,
          settings: {
            theme: "dark",
            colors: {
              primary: "#000000",
              secondary: "#ffffff",
            },
            seo: {
              title: "",
              description: "",
              keywords: [],
            },
            socials: {},
          },
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            user_cookie: cookie,
          },
        }
      );

      // Check if domain creation was successful
      if (response.status !== 201) {
        throw new Error("Failed to create domain");
      }

      // Update domain config context
      await fetchDomainConfig();
    } catch (error) {
      console.error("Error creating domain:", error);
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        setState((prev) => ({
          ...prev,
          domainError: "This domain name is already taken",
        }));
      } else {
        setState((prev) => ({
          ...prev,
          domainError: "Domain creation failed, Name Not Available",
        }));
      }
    } finally {
      setLoading((prev) => ({ ...prev, create: false }));
    }
  }, [domainOptions.domain, updateDomainConfig]);

  const handleBannerUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const url = URL.createObjectURL(file);
        setState((prev) => ({
          ...prev,
          tempBannerFile: file,
          tempBannerUrl: url,
          showCropper: true,
          crop: { x: 0, y: 0 },
          zoom: 1,
          croppedAreaPixels: null,
        }));
      }
    },
    []
  );

  const handleLogoUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setState((prev) => ({
          ...prev,
          tempLogoUrl: url,
          showLogoPreview: true,
        }));
      }
    },
    []
  );

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setState((prev) => ({ ...prev, croppedAreaPixels }));
    },
    []
  );

  const onCropCancel = useCallback(() => {
    if (state.tempBannerUrl) {
      URL.revokeObjectURL(state.tempBannerUrl);
    }
    setState((prev) => ({
      ...prev,
      showCropper: false,
      tempBannerFile: null,
      tempBannerUrl: null,
      croppedAreaPixels: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
    }));
  }, [state.tempBannerUrl]);

  const onCropSave = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, banner: true }));
      if (!state.tempBannerFile || !state.croppedAreaPixels) {
        console.error("Missing required data:", {
          file: state.tempBannerFile,
          cropArea: state.croppedAreaPixels,
        });
        return;
      }

      // Set loading state
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, banner: true },
      }));

      // Create canvas and draw cropped image
      const image = new Image();
      image.src = state.tempBannerUrl;
      await new Promise((resolve) => {
        image.onload = resolve;
      });

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Failed to get canvas context");
      }

      // Set canvas dimensions
      canvas.width = 1920;
      canvas.height = 700;

      // Calculate scale
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      // Draw the cropped image
      ctx.drawImage(
        image,
        state.croppedAreaPixels.x * scaleX,
        state.croppedAreaPixels.y * scaleY,
        state.croppedAreaPixels.width * scaleX,
        state.croppedAreaPixels.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      // Convert to blob
      const blob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
      );

      // Create file from blob
      const croppedFile = new File([blob], state.tempBannerFile.name, {
        type: "image/jpeg",
      });

      // Prepare form data
      const formData = new FormData();
      formData.append("file", croppedFile);

      // Upload file
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE}file/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            user_cookie: cookie,
          },
        }
      );
      const data = response.data;

      // Update domain config
      const newBanner = { id: data };

      // First update the local state
      setDomainOptions((prev) => ({
        ...prev,
        banner: newBanner,
      }));

      // Then update the server config
      await updateDomainConfig({
        banner: newBanner,
      });

      // Cleanup and close modal
      if (state.tempBannerUrl) {
        URL.revokeObjectURL(state.tempBannerUrl);
      }

      // Reset all state after successful save
      setState((prev) => ({
        ...prev,
        showCropper: false,
        tempBannerFile: null,
        tempBannerUrl: null,
        croppedAreaPixels: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
        loading: { ...prev.loading, banner: false },
      }));
    } catch (error) {
      console.error("Error saving cropped image:", error);
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, banner: false },
      }));
      // Optionally show an error message to the user
      alert("Failed to save the banner image. Please try again.");
    }
  }, [
    state.tempBannerFile,
    state.croppedAreaPixels,
    state.crop,
    state.zoom,
    state.tempBannerUrl,
    updateDomainConfig,
    setDomainOptions,
  ]);

  const handleLogoSave = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, logo: true }));
      setState((prev) => ({ ...prev, loading: { ...prev.loading, logo: true } }));

      if (!state.tempLogoUrl) {
        throw new Error("No logo file");
      }

      // Convert data URL to blob
      const response = await fetch(state.tempLogoUrl);
      const blob = await response.blob();
      const file = new File([blob], "logo.jpg", { type: "image/jpeg" });

      // Upload to server
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE}file/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            user_cookie: cookie,
          },
        }
      );
      const data = uploadResponse.data;

      // Update domain config
      const newLogo = { id: data };
      const updatedDomainOptions = {
        ...domainOptions,
        logo: {
          id: newLogo.id,
        },
      };

      await updateDomainConfig(updatedDomainOptions);
      setDomainOptions(updatedDomainOptions);

      // Clean up
      setState((prev) => ({
        ...prev,
        showLogoPreview: false,
        tempLogoUrl: null,
      }));

      // Reset loading state
      setLoading((prev) => ({ ...prev, logo: false }));

      toast({
        title: "Logo updated successfully",
        description: "Logo updated successfully",
        status: "success",
      });
    } catch (error) {
      console.error("Error saving logo:", error);
      toast({
        title: "Failed to update logo",
        description: "Failed to update logo",
        status: "error",
      });
    } finally {
      setState((prev) => ({ ...prev, loading: { ...prev.loading, logo: false } }));
    }
  }, [state.tempLogoUrl, domainOptions, updateDomainConfig, setDomainOptions]);

  const handleColorChange = (
    value: string,
    type: "primary" | "secondary" | "theme"
  ) => {
    if (type === "theme") {
      setDomainOptions((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          theme: value as "light" | "dark",
        },
      }));
    } else {
      setDomainOptions((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          colors: {
            ...prev.settings.colors,
            [type]: value,
          },
        },
      }));
    }
  };

  const handleSeoChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof DomainConfig["settings"]["seo"]
  ) => {
    const value = e.target.value;
    setDomainOptions((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        seo: {
          ...prev.settings.seo,
          [field]:
            field === "keywords"
              ? value.split(",").map((k) => k.trim())
              : value,
        },
      },
    }));
  };

  const handleSocialChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof DomainConfig["settings"]["socials"]
  ) => {
    if (!e.target.value) return;
    setDomainOptions((prev) => ({
      ...prev,
      socials: { ...prev.settings.socials, [field]: e.target.value },
    }));
  };

  const handleProfileChange = (platform: string, value: string) => {
    setDomainOptions((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        socials: {
          ...prev.settings.socials,
          [platform]: value,
        },
      },
    }));
  };

  const handleSectionSave = async (section: string) => {
    setLoading((prev) => ({ ...prev, [section]: true }));

    try {
      if (section === "banner") {
        await updateDomainConfig({
          banner: domainOptions.banner,
        });
      } else if (section === "socials") {
        await updateDomainConfig({
          settings: {
            ...domainOptions.settings,
            socials: domainOptions.settings.socials,
          },
        });
      } else {
        await updateDomainConfig({
          settings: domainOptions.settings,
        });
      }
      // Handle other sections...

      toast({
        title: "Saved",
        description: `Saved ${section}`,
        status: "success",
      });
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
      toast({
        title: "Error",
        description: `Error saving ${section}`,
        status: "error",
      });
    } finally {
      setLoading((prev) => ({ ...prev, [section]: false }));
    }
  };

  const handleSave = async () => {
    setLoading((prev) => ({ ...prev, all: true }));
    toast({
      title: "Saving...",
      description: "Saving website settings...",
      status: "info"
    });
    try {
      await updateDomainConfig(domainOptions);
      toast({
        title: "Saved",
        description: "Saved website settings",
        status: "success"
      });
    } catch (error) {
      console.error("Error saving settings:", error);
    } finally {
      setLoading((prev) => ({ ...prev, all: false }));
    }
  };

  const handleReset = () => {
    setDomainOptions(domainConfig);
  };

  const handleZoom = useCallback((delta: number) => {
    setState((prev) => ({
      ...prev,
      zoom: Math.min(Math.max(prev.zoom + delta, 1), 3),
    }));
  }, []);

  if (fetchingDomainConfig) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <CircularProgress size={32} />
          <p>Loading website settings...</p>
        </div>
      </div>
    );
  }

  // Add domain creation UI when no domain exists
  /* if (!fetchingDomainConfig && !domainConfig) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
        <h1>No Website Found</h1>
        <p>
          Check back later to create your Loop Fans website.
        </p>
      </div>
      </div>
    );
  } */

  if (!fetchingDomainConfig && !domainConfig) {
    return (
      <div className={styles.container}>
        <div className={styles.createDomainSection}>
          <div className={styles.header} style={{ marginBottom: "1rem" }}>
            <h1>Create Your Website</h1>
            <p>
              Set up your unique Loop Fans domain to showcase your content and
              connect with your audience.
            </p>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <div className={styles.titleContent}>
                <FiGlobe />
                <h2>Choose Your Domain</h2>
              </div>
            </div>
            <div className={styles.domainInputGroup}>
              <input
                type="text"
                value={domainOptions?.domain?.split(".")[0] || ""}
                onChange={handleDomainChange}
                placeholder="Enter your subdomain"
                maxLength={63}
                className={state.domainError ? styles.error : ""}
              />
              <div className={styles.domainSuffix}>.loop.fans</div>
            </div>
            {state.domainError && (
              <div className={styles.errorMessage} role="alert">
                <div className={styles.errorIcon}>
                  <FiX />
                </div>
                <div className={styles.errorContent}>
                  <div className={styles.errorTitle}>Domain Not Available</div>
                  <div className={styles.errorDescription}>
                    {state.domainError === "Domain creation failed, Name Not Available"
                      ? "This domain name is already taken. Please try a different name."
                      : "There was an error creating your domain. Please try again."}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.domainNote}>
              <FiInfo />
              <span>
                Choose a unique name for your Loop Fans website. This will be
                your permanent address.
              </span>
            </div>

            <div className={styles.domainGuidelines}>
              <h3>Domain Guidelines:</h3>
              <ul>
                <li>Use only lowercase letters, numbers, and hyphens</li>
                <li>Must start and end with a letter or number</li>
                <li>Length between 3 and 63 characters</li>
                <li>Choose something memorable and related to your brand</li>
              </ul>
            </div>

            <button
              className={styles.createDomainButton}
              onClick={handleCreateDomain}
              disabled={
                loading["create"] ||
                !domainOptions?.domain ||
                domainOptions.domain.split(".")[0].length < 3
              }
            >
              {loading["create"] ? (
                <>
                  <CircularProgress size={16} />
                  Creating your website...
                </>
              ) : (
                <>
                  <FiSend />
                  Create My Website
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Website Management</h1>
        <p>
          Customize your website's appearance and optimize it for search engines
          and social media sharing.
        </p>
      </div>

      <div className={styles.topSections}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <div className={styles.titleContent}>
              <FiGlobe />
              <h2>Your Website</h2>
            </div>
            <Button
              onClick={() =>
                window.open(`https://${domainOptions?.domain}`, "_blank")
              }
              className={styles.viewButton}
            >
              <FiExternalLink />
              View Website
            </Button>
          </div>
          <div className={styles.domainInputGroup}>
            <input
              type="text"
              value={domainOptions?.domain?.split(".")[0]}
              onChange={handleDomainChange}
              placeholder="Enter your subdomain"
              maxLength={63}
              disabled
            />
            <div className={styles.domainSuffix}>.loop.fans</div>
          </div>
          <div className={styles.domainDescription}>
            <div className={styles.domainNote}>
              <FiInfo />
              <span>
                This is your unique domain on Loop Fans. Contact support to
                change it.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <div className={styles.titleContent}>
              <FiDroplet />
              <h2>Website Settings</h2>
            </div>
            <Button
              onClick={() => handleSectionSave("colors")}
              disabled={loading["colors"]}
              className={styles.sectionSaveButton}
            >
              {loading["colors"] ? (
                <>
                  <CircularProgress size={14} />
                  Saving...
                </>
              ) : (
                <>
                  <FiSave />
                  Save
                </>
              )}
            </Button>
          </div>

          <div className={styles.formGroup}>
            <label>Theme</label>
            <div className={styles.themeSelector}>
              <div
                className={`${styles.themeOption} ${
                  domainOptions?.settings?.theme == "light"
                    ? styles.selected
                    : ""
                }`}
                onClick={() => handleColorChange("light", "theme")}
              >
                <div className={styles.themeIcon}>
                  <FiSun />
                </div>
                <div className={styles.themeName}>Light</div>
              </div>
              <div
                className={`${styles.themeOption} ${
                  !domainOptions?.settings?.theme ||
                  domainOptions?.settings?.theme == "dark"
                    ? styles.selected
                    : ""
                }`}
                onClick={() => handleColorChange("dark", "theme")}
              >
                <div className={styles.themeIcon}>
                  <FiMoon />
                </div>
                <div className={styles.themeName}>Dark</div>
              </div>
            </div>
          </div>

          <div className={styles.formGroup}>
            <div className={styles.colorGrid}>
              <div className={styles.colorItem}>
                <div className={styles.colorLabel}>Primary Color</div>
                <div className={styles.colorPicker}>
                  <div
                    className={styles.colorPreview}
                    onClick={() =>
                      setShowColorPicker({
                        ...showColorPicker,
                        primary: !showColorPicker.primary,
                      })
                    }
                  >
                    <div
                      className={styles.colorOverlay}
                      style={{
                        backgroundColor:
                          domainOptions?.settings?.colors?.primary ?? "#000000",
                      }}
                    />
                  </div>
                  {showColorPicker.primary && (
                    <>
                      <div className={styles.pickerPopover}>
                        <ChromePicker
                          color={
                            domainOptions?.settings?.colors?.primary ??
                            "#000000"
                          }
                          onChange={(color) =>
                            handleColorChange(color.hex, "primary")
                          }
                        />
                      </div>
                      <div
                        className={styles.pickerCover}
                        onClick={() =>
                          setShowColorPicker({
                            ...showColorPicker,
                            primary: false,
                          })
                        }
                      />
                    </>
                  )}
                </div>
              </div>

              <div className={styles.colorItem}>
                <div className={styles.colorLabel}>Secondary Color</div>
                <div className={styles.colorPicker}>
                  <div
                    className={styles.colorPreview}
                    onClick={() =>
                      setShowColorPicker({
                        ...showColorPicker,
                        secondary: !showColorPicker.secondary,
                      })
                    }
                  >
                    <div
                      className={styles.colorOverlay}
                      style={{
                        backgroundColor:
                          domainOptions?.settings?.colors?.secondary ??
                          "#000000",
                      }}
                    />
                  </div>
                  {showColorPicker.secondary && (
                    <>
                      <div className={styles.pickerPopover}>
                        <ChromePicker
                          color={
                            domainOptions?.settings?.colors?.secondary ??
                            "#000000"
                          }
                          onChange={(color) =>
                            handleColorChange(color.hex, "secondary")
                          }
                        />
                      </div>
                      <div
                        className={styles.pickerCover}
                        onClick={() =>
                          setShowColorPicker({
                            ...showColorPicker,
                            secondary: false,
                          })
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.imagesSection}>
        <div className={`${styles.section} ${styles.logoSection}`}>
          <div className={styles.sectionTitle}>
            <div className={styles.titleContent}>
              <FiImage />
              <h2>Logo</h2>
            </div>
          </div>
          <div className={styles.imageUpload}>
            <input
              type="file"
              accept="image/*"
              onChange={handleLogoUpload}
              id="logo-upload"
              hidden
            />
            <label
              htmlFor="logo-upload"
              className={`${styles.uploadArea} ${styles.logoBanner}`}
            >
              {domainOptions?.logo?.id ? (
                <div className={styles.preview}>
                  <img
                    src={DirectusImage(domainOptions.logo.id)}
                    alt="Logo preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ) : (
                <>
                  <FiUpload />
                  <p style={{ textAlign: "center" }}>Click or drag image to upload</p>
                </>
              )}
            </label>
            <p className={styles.hint}>
              <FiInfo /> Recommended size: 465x80 pixels. Maximum file size:
              2MB
            </p>
          </div>
        </div>

        <div className={`${styles.section} ${styles.bannerSection}`}>
          <div className={styles.sectionTitle}>
            <div className={styles.titleContent}>
              <FiImage />
              <h2>Banner Image</h2>
            </div>
          </div>
          <div className={styles.imageUpload}>
            <input
              type="file"
              accept="image/*"
              onChange={handleBannerUpload}
              id="banner-upload"
              hidden
            />
            <label htmlFor="banner-upload" className={styles.uploadArea}>
              {domainOptions?.banner?.id ? (
                <div className={styles.preview}>
                  <img
                    src={DirectusImage(domainOptions.banner.id)}
                    alt="Banner preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                <>
                  <FiUpload />
                  <p style={{ textAlign: "center" }}>Click or drag image to upload</p>
                </>
              )}
            </label>
            <p className={styles.hint}>
              <FiInfo /> Recommended size: 1920x700 pixels. Maximum file size:
              5MB
            </p>
          </div>
        </div>
      </div>

      {state.showCropper && state.tempBannerUrl && (
        <div className={styles.cropperModal}>
          <div className={styles.cropperContainer}>
            <Cropper
              image={state.tempBannerUrl}
              crop={state.crop}
              zoom={state.zoom}
              aspect={1920 / 700}
              onCropChange={(crop) => {
                setState((prev) => ({ ...prev, crop }));
              }}
              onZoomChange={(zoom) => {
                setState((prev) => ({ ...prev, zoom }));
              }}
              onCropComplete={(croppedArea, croppedAreaPixels) => {
                onCropComplete(croppedArea, croppedAreaPixels);
              }}
              objectFit="horizontal-cover"
              minZoom={1}
              maxZoom={3}
              cropShape="rect"
              showGrid={true}
            />
            <div className={styles.cropperControls}>
              <div className={styles.controlsLeft}>
                <span>Adjust and crop your image</span>
                <div className={styles.zoomControls}>
                  <Button
                    onClick={() => handleZoom(-0.1)}
                    disabled={state.zoom <= 1}
                  >
                    <FiZoomOut />
                  </Button>
                  <Button
                    onClick={() => handleZoom(0.1)}
                    disabled={state.zoom >= 3}
                  >
                    <FiZoomIn />
                  </Button>
                </div>
              </div>
              <div className={styles.controlsRight}>
                <Button className={styles.cancelButton} onClick={onCropCancel}>
                  {loading["banner"] || loading["logo"] ? (
                    <CircularProgress size={14} />
                  ) : (
                    <FiX />
                  )}
                  Cancel
                </Button>
                <Button
                  className={styles.saveButton}
                  onClick={onCropSave}
                  disabled={loading["banner"]}
                >
                  {loading["banner"] || loading["logo"] ? (
                    <>
                      <CircularProgress size={14} />
                      Saving...
                    </>
                  ) : (
                    <>
                      <FiSave />
                      Save Changes
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.showLogoPreview && state.tempLogoUrl && (
        <div className={styles.previewModal}>
          <div className={styles.previewContainer}>
            <div className={styles.previewContent}>
              <h3>Preview Logo</h3>
              <div className={styles.imagePreview}>
                <img src={state.tempLogoUrl} alt="Logo preview" />
              </div>
              <div className={styles.previewControls}>
                <Button
                  className={styles.cancelButton}
                  onClick={() => {
                    if (state.tempLogoUrl) {
                      URL.revokeObjectURL(state.tempLogoUrl);
                    }
                    setState((prev) => ({
                      ...prev,
                      showLogoPreview: false,
                      tempLogoUrl: null,
                    }));
                  }}
                  disabled={loading["logo"]}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.saveButton}
                  onClick={handleLogoSave}
                  disabled={loading["logo"]}
                >
                  {loading["logo"] ? (
                    <>
                      <CircularProgress size={14} />
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <div className={styles.titleContent}>
            <FiShare2 />
            <h2>Social Profiles</h2>
          </div>
          <Button
            onClick={() => handleSectionSave("socials")}
            disabled={loading["socials"]}
            className={styles.sectionSaveButton}
          >
            {loading["socials"] ? (
              <>
                <CircularProgress size={14} />
                Saving...
              </>
            ) : (
              <>
                <FiSave />
                Save
              </>
            )}
          </Button>
        </div>

        <div className={styles.socialProfiles}>
          <div className={styles.formGroup}>
            <label>
              <RiTwitterXLine />
              X Profile URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.x || ""}
              onChange={(e) => handleProfileChange("x", e.target.value)}
              placeholder="https://x.com/username"
            />
          </div>

          <div className={styles.formGroup}>
            <label>
              <FiFacebook />
              Facebook Profile URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.facebook || ""}
              onChange={(e) => handleProfileChange("facebook", e.target.value)}
              placeholder="https://facebook.com/username"
            />
          </div>

          <div className={styles.formGroup}>
            <label>
              <FiInstagram />
              Instagram Profile URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.instagram || ""}
              onChange={(e) => handleProfileChange("instagram", e.target.value)}
              placeholder="https://instagram.com/username"
            />
          </div>

          <div className={styles.formGroup}>
            <label>
              <FiYoutube />
              YouTube Channel URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.youtube || ""}
              onChange={(e) => handleProfileChange("youtube", e.target.value)}
              placeholder="https://youtube.com/c/channelname"
            />
          </div>

          <div className={styles.formGroup}>
            <label>
              <SiTiktok />
              TikTok Profile URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.tiktok || ""}
              onChange={(e) => handleProfileChange("tiktok", e.target.value)}
              placeholder="https://tiktok.com/@username"
            />
          </div>

          <div className={styles.formGroup}>
            <label>
              <SiSpotify />
              Spotify Profile URL
            </label>
            <input
              type="url"
              value={domainOptions?.settings?.socials?.spotify || ""}
              onChange={(e) => handleProfileChange("spotify", e.target.value)}
              placeholder="https://spotify.com/username"
            />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <div className={styles.titleContent}>
            <FiShare2 />
            <h2>SEO Settings</h2>
          </div>
          <Button
            onClick={() => handleSectionSave("seo")}
            disabled={loading["seo"]}
            className={styles.sectionSaveButton}
          >
            {loading["seo"] ? (
              <>
                <CircularProgress size={14} />
                Saving...
              </>
            ) : (
              <>
                <FiSave />
                Save
              </>
            )}
          </Button>
        </div>

        <div className={styles.previewContainer}>
          <p className={styles.previewLabel}>
            Preview how your page will appear when shared on social media:
          </p>
          <div className={styles.previewCards}>
            <div className={styles.previewCard}>
              <div className={styles.previewCardHeader}>
                <RiTwitterXLine />
                <span>X Card</span>
              </div>
              <div className={styles.socialPreview}>
                <div className={styles.previewImage}>
                  {domainOptions?.banner ? (
                    <img
                      src={DirectusImage(domainOptions.banner.id)}
                      alt="Banner"
                    />
                  ) : (
                    <div className={styles.placeholderImage}>
                      <FiImage />
                    </div>
                  )}
                </div>
                <div className={styles.previewContent}>
                  <div className={styles.previewTitle}>
                    {domainOptions?.settings?.seo?.title || "Your Page Title"}
                  </div>
                  <div className={styles.previewDescription}>
                    {domainOptions?.settings?.seo?.description ||
                      "Your page description"}
                  </div>
                  <div className={styles.previewDomain}>
                    {domainOptions?.domain || "your-domain.loop.fans"}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.previewCard}>
              <div className={styles.previewCardHeader}>
                <FiFacebook />
                <span>Facebook Card</span>
              </div>
              <div className={styles.socialPreview}>
                <div className={styles.previewImage}>
                  {domainOptions?.banner ? (
                    <img
                      src={DirectusImage(domainOptions.banner.id)}
                      alt="Banner"
                    />
                  ) : (
                    <div className={styles.placeholderImage}>
                      <FiImage />
                    </div>
                  )}
                </div>
                <div className={styles.previewContent}>
                  <div className={styles.previewDomain}>
                    {domainOptions?.domain || "your-domain.loop.fans"}
                  </div>
                  <div className={styles.previewTitle}>
                    {domainOptions?.settings?.seo?.title || "Your Page Title"}
                  </div>
                  <div className={styles.previewDescription}>
                    {domainOptions?.settings?.seo?.description ||
                      "Your page description"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Title</label>
          <input
            type="text"
            value={domainOptions?.settings?.seo?.title}
            onChange={(e) => handleSeoChange(e, "title")}
            placeholder="Enter SEO title"
            maxLength={60}
          />
          <div className={styles.hint}>
            {domainOptions?.settings?.seo?.title?.length || 0}/60 characters
          </div>
        </div>
        <div className={styles.formGroup}>
          <label>Description</label>
          <textarea
            value={domainOptions?.settings?.seo?.description}
            onChange={(e) => handleSeoChange(e, "description")}
            placeholder="Enter SEO description"
            maxLength={160}
          />
          <div className={styles.hint}>
            {domainOptions?.settings?.seo?.description?.length || 0}/160
            characters
          </div>
        </div>
        <div className={styles.formGroup}>
          <label>Keywords</label>
          <input
            type="text"
            value={domainOptions?.settings?.seo?.keywords?.join(", ")}
            onChange={(e) => handleSeoChange(e, "keywords")}
            placeholder="Enter keywords separated by commas"
          />
          <div className={styles.hint}>Separate keywords with commas</div>
        </div>
      </div>

      <div className={styles.actions}>
        {/* <Button className={styles.resetButton} onClick={handleReset} disabled={loading['all']}>
          Reset All Changes
        </Button> */}
        <Button
          className={styles.saveButton}
          onClick={handleSave}
          disabled={loading["all"]}
        >
          {loading["all"] ? (
            <>
              <CircularProgress size={14} />
              Saving Changes...
            </>
          ) : (
            <>
              <FiSave />
              Save All Changes
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
