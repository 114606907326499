import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import SearchDrawer from "./SearchDrawer";
import NotiDrawer from "./NotiDrawer";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import Header from "header/Header";
import { isCreator } from "app/core/common";
import MobileMenu from "./MobileMenu";
import classNames from "classnames";
import { DomainConfig, useDomainConfig } from 'app/context/domainConfigContext';

export default function Sidebar({
  isLoggedIn,
  children,
  containerRef,
}: {
  isLoggedIn: any;
  children: any;
  containerRef: any;
}) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const { domainConfig } = useDomainConfig();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [noheader, setNoHeader] = useState(false);
  const history = useHistory();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

  const menuItems = [
    {
      title: "Dashboard",
      hidden: false,
      icon: "/assets/icons/home.svg",
      items: [
        {
          title: "Overview",
          to: "/",
        },
        {
          title: "Fans",
          to: "/fans",
          disabled: false,
          badge: "Coming Soon"
        },
      ]
    },
    {
      title: "Drops",
      hidden: false,
      icon: "/assets/icons/collections.svg",
      items: [
        {
          title: "New Drop",
          to: "/new-release",
        },
        {
          title: "Draft Drops",
          to: "/drafts",
        },
        {
          title: "Live Drops",
          to: "/liveCollections",
        }
      ]
    },
    {
      title: "Website",
      icon: "/assets/icons/settings.svg",
      //hidden: !domainConfig,
      items: [
        {
          title: "Content Blocks",
          to: "/domain/content",
          hidden: !domainConfig,
        },
        {
          title: "Website Settings",
          to: "/domain/settings",
        },
        /* {
          title: "Billing",
          to: "/billing",
          icon: "/assets/icons/settings.svg",
        }, */
      ]
    }
  ];

  const handleNavigation = (to: string, elementId?: string): void => {
    history.push(to);
    if (elementId) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    if (screenWidth <= 768) {
      setIsSidebarOpen(false);
      setMobileDrawer(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setIsSidebarOpen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header
        noheader={noheader}
        onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        isSidebarOpen={isSidebarOpen}
      />
      <div className={styles.sidebar}>
        <div
          className={`${styles.sidebarContent} ${
            !isSidebarOpen ? styles.collapsed : ""
          }`}
        >
          <div className={styles.navbar}>
            <div className={styles.navSection}>
              {menuItems.map((section, sectionIndex) => (
                <div key={sectionIndex} className={styles.menuSection} style={{display: section.hidden ? "none" : "block"}}>
                  <div className={styles.sectionTitle}>
                    <div className={styles.titleContent}>
                      <img 
                        src={section?.icon}
                        alt={section.title}
                      />
                      <span>{section.title}</span>
                    </div>
                    {section.title === "Drops" && (
                      <button
                        className={styles.newReleaseButton}
                        onClick={() => handleNavigation("/", "studio_options")}
                      >
                        Create
                      </button>
                    )}
                  </div>
                  <ul className={styles.menuItems}>
                    {section.items.map((item, itemIndex) => (
                      item.title !== "New Drop" && (
                        <li
                          key={item.title}
                          className={classNames(styles.menuItem, {
                            [styles.active]: location.pathname === item.to,
                          })}
                          hidden={item.hidden}
                          onClick={() => !item.disabled && handleNavigation(item.to)}
                          data-badge={item.badge}
                          {...(item.disabled ? { disabled: true } : {})}
                        >
                          {item.title}
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={classNames(styles.mainContent, { [styles.collapsed]: !isSidebarOpen })} ref={containerRef}>
          {children}
        </div>

        {showLoginModal && (
          <LoginModal showLoginModal={showLoginModal} showSignup={false} />
        )}

        <SearchDrawer
          isOpen={searchDrawer}
          onClose={() => setSearchDrawer(false)}
        />
        <NotiDrawer isOpen={notiDrawer} onClose={() => setNotiDrawer(false)} />
        {screenWidth <= 768 && (
          <MobileMenu
            isOpen={mobileDrawer}
            onClose={() => setMobileDrawer(false)}
          />
        )}
      </div>
    </>
  );
}
