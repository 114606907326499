import React, { useState } from 'react';
import styles from './Billing.module.scss';

interface DomainPlan {
  id: string;
  name: string;
  price: number;
  billingPeriod: 'monthly' | 'yearly';
  features: string[];
  storageLimit: string;
  bandwidthLimit: string;
}

interface CurrentPlan {
  planId: string;
  status: 'active' | 'expired' | 'cancelled';
  nextBillingDate: string;
  customDomain?: string;
}

const Billing: React.FC = () => {
  const [availablePlans] = useState<DomainPlan[]>([
    {
      id: '1',
      name: 'Starter',
      price: 9.99,
      billingPeriod: 'monthly',
      features: [
        'Custom domain support',
        '10GB storage',
        '100GB bandwidth',
        'Basic analytics',
        'Email support'
      ],
      storageLimit: '10GB',
      bandwidthLimit: '100GB'
    },
    {
      id: '2',
      name: 'Professional',
      price: 24.99,
      billingPeriod: 'monthly',
      features: [
        'Custom domain support',
        '50GB storage',
        'Unlimited bandwidth',
        'Advanced analytics',
        'Priority support',
        'Custom SSL certificate'
      ],
      storageLimit: '50GB',
      bandwidthLimit: 'Unlimited'
    }
  ]);

  const [currentPlan] = useState<CurrentPlan>({
    planId: '1',
    status: 'active',
    nextBillingDate: '2024-01-15',
    customDomain: 'myartwork.com'
  });

  const [showDomainModal, setShowDomainModal] = useState(false);

  const getCurrentPlanDetails = () => {
    return availablePlans.find(plan => plan.id === currentPlan.planId);
  };

  return (
    <div className={styles.domainManagement}>
      <div className={styles.header}>
        <h1>Domain Management</h1>
        <p>Manage your domain settings and subscription plan</p>
      </div>

      <div className={styles.currentPlanSection}>
        <div className={styles.sectionHeader}>
          <h2>Current Plan</h2>
          <span className={`${styles.status} ${styles[currentPlan.status]}`}>
            {currentPlan.status}
          </span>
        </div>

        <div className={styles.planDetails}>
          <div className={styles.planInfo}>
            <h3>{getCurrentPlanDetails()?.name}</h3>
            <p className={styles.price}>
              ${getCurrentPlanDetails()?.price}
              <span>/{getCurrentPlanDetails()?.billingPeriod}</span>
            </p>
            <p className={styles.nextBilling}>
              Next billing date: {new Date(currentPlan.nextBillingDate).toLocaleDateString()}
            </p>
          </div>

          <div className={styles.usageStats}>
            <div className={styles.stat}>
              <span>Storage Used</span>
              <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: '45%' }}></div>
              </div>
              <span>4.5GB of {getCurrentPlanDetails()?.storageLimit}</span>
            </div>
            <div className={styles.stat}>
              <span>Bandwidth Used</span>
              <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: '30%' }}></div>
              </div>
              <span>30GB of {getCurrentPlanDetails()?.bandwidthLimit}</span>
            </div>
          </div>
        </div>

        <div className={styles.domainSettings}>
          <h3>Domain Settings</h3>
          <div className={styles.domainInfo}>
            <div className={styles.currentDomain}>
              <span>Current Domain:</span>
              <strong>{currentPlan.customDomain || 'Not configured'}</strong>
            </div>
            <button 
              className={styles.editButton}
              onClick={() => setShowDomainModal(true)}
            >
              Edit Domain
            </button>
          </div>
        </div>
      </div>

      <div className={styles.availablePlansSection}>
        <h2>Available Plans</h2>
        <div className={styles.plansGrid}>
          {availablePlans.map(plan => (
            <div 
              key={plan.id} 
              className={`${styles.planCard} ${plan.id === currentPlan.planId ? styles.current : ''}`}
            >
              <div className={styles.planHeader}>
                <h3>{plan.name}</h3>
                <div className={styles.price}>
                  ${plan.price}
                  <span className={styles.period}>/{plan.billingPeriod}</span>
                </div>
              </div>
              <div className={styles.planFeatures}>
                {plan.features.map((feature, index) => (
                  <div key={index} className={styles.feature}>
                    <span className={styles.checkmark}>✓</span>
                    {feature}
                  </div>
                ))}
              </div>
              <button 
                className={`${styles.planButton} ${plan.id === currentPlan.planId ? styles.current : ''}`}
              >
                {plan.id === currentPlan.planId ? 'Current Plan' : 'Upgrade'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Billing;
