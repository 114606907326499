import { useHook } from 'app/hooks/common';
import { useWallet } from 'app/Loop_Reuseable_Component/packages/loop_wallet';
import React, { createContext, useContext, useState, useEffect } from 'react';

interface DomainConfigContextType {
  domainConfig: DomainConfig | null;
  loading: boolean;
  error: string | null;
  updateDomainConfig: (config: Partial<DomainConfig>) => Promise<void>;
  fetchDomainConfig: () => Promise<void>;
}

interface Asset {
  id: string;
}

interface DomainColors {
  primary: string;
  secondary: string;
}

interface settings {
  theme: 'dark' | 'light';
  logoText: string;
  colors: DomainColors;
  seo?: {
    title: string;
    description: string;
    keywords: string[];
  };
  socials?: {
    x?: string;
    facebook?: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
    spotify?: string;
  };
}

export interface DomainConfig {
  id: string;
  status: 'active' | 'inactive';
  domain: string;
  logo: Asset;
  banner: Asset;
  settings: settings;
}

const DomainConfigContext = createContext<DomainConfigContextType | undefined>(undefined);

export const DomainConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { cookie, userInfo } = useHook();
  const [domainConfig, setDomainConfig] = useState<DomainConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchDomainConfig();
  }, [cookie, userInfo]);

  const fetchDomainConfig = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE}wl/domain`, {
        method: 'GET',
        credentials: 'include',  // This ensures cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
          'user_cookie': cookie
        }
      });
      const data = await response.json();
      setDomainConfig(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch domain configuration');
      console.error('Error fetching domain config:', err);
    } finally {
      setLoading(false);
    }
  };

  const updateDomainConfig = async (config: Partial<DomainConfig>) => {
    try {
      //setLoading(true);
      // TODO: Replace with actual API call
      const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE}wl/domain/${domainConfig.domain}`, {
        method: 'PATCH',
        credentials: 'include',  // This ensures cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
          'user_cookie': cookie
        },
        body: JSON.stringify(config),
      });
      const updatedConfig = await response.json();
      setDomainConfig(updatedConfig);
      setError(null);
    } catch (err) {
      setError('Failed to update domain configuration');
      console.error('Error updating domain config:', err);
    } finally {
      //setLoading(false);
    }
  };

  return (
    <DomainConfigContext.Provider
      value={{
        domainConfig,
        loading,
        error,
        updateDomainConfig,
        fetchDomainConfig,
      }}
    >
      {children}
    </DomainConfigContext.Provider>
  );
};

export const useDomainConfig = () => {
  const context = useContext(DomainConfigContext);
  if (context === undefined) {
    throw new Error('useDomainConfig must be used within a DomainConfigProvider');
  }
  return context;
};
